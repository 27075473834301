<template>


<form action="https://gerpsuden.azurewebsites.net/#/admins/point-of-sale/report" @submit.stop="signInButtonPressed" class="paymentWidgets" data-brands="VISA MASTER DINERS DISCOVER AMEX"></form>

</template>




<script>

export default {
  name: 'VueDataFast',


  props:{

reference: {
    type: String
 }

},


 
  async mounted() {


    let recaptchaScript = document.createElement('script')
    let recaptchaScript1 = document.createElement('script')

      recaptchaScript.setAttribute('src', 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=1B9B69F9AE0399D2814DCF8BA3223BAA.uat01-vm-tx02')

       recaptchaScript1.setAttribute('type', 'text/javascript')

      console.log("IMPRIMO")


      recaptchaScript1.innerHTML = `

      var wpwlOptions= {
        style: "card",
        locale: "es",
        labels: {cvv: "CVV", cardHolder: "Nombre(Igual que en la tarjeta)"},
            
       }
`


      
  
      document.head.appendChild(recaptchaScript)
      document.head.appendChild(recaptchaScript1)



  



    this.$nextTick(() => {
      const that=this
    let paymentCheckout = new window.PaymentCheckout.modal({
    env_mode: "stg", // `prod`, `stg`, `local` to change environment. Default is `stg`
    onOpen: function () {
      console.log("modal open");
    },
    onClose() {
      console.log("modal closed");
      console.log(this)
      that.$emit('returnMessageFromNuvei')
    },
    onResponse: function (response) { // The callback to invoke when the Checkout process is completed

      console.log(response)

      try {
        console.log(response.transaction.status)
        if (response.transaction.status=="success"){
      that.$emit('messageFromNuvei', true, response.transaction.id, response.transaction.authorization_code)
        }else{
          that.$emit('returnMessageFromNuvei')
          alert("El pago no ha sido procesado. Inténtelo nuevamente")
        }
      } catch (error) {
        alert("Ha existido un error y el pago no ha sido procesado.")
        that.$emit('returnMessageFromNuvei')
      }
      //emit
      /*
        In Case of an error, this will be the response.
        response = {
          "error": {
            "type": "Server Error",
            "help": "Try Again Later",
            "description": "Sorry, there was a problem loading Checkout."
          }
        }
        When the User completes all the Flow in the Checkout, this will be the response.
        response = {
          "transaction":{
              "status": "success", // success or failure
              "id": "CB-81011", // transaction_id
              "status_detail": 3 // for the status detail please refer to: https://paymentez.github.io/api-doc/#status-details
          }
        }
      */
      console.log("modal response");
    }
  });

  
    paymentCheckout.open({
      reference: this.reference // reference received for Payment Gateway
    });
  

  window.addEventListener('popstate', function () {
    paymentCheckout.close();
    
  });
})
  },


  data() {
    return {
      paid: false,
    };
  },

  methods: {

    signInButtonPressed(){
      console.log("miauuuu")
    }


  }

  

 
};
</script>



<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>