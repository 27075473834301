<template>

    <div >   
                      <ul class="nav nav-tabs" role="tablist" id="myTab">
                                <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-bs-toggle="tab" id="description-tab" href="#specifications">Localidades</a></li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade description" role="tabpanel" id="description">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <figure class="figure"><img class="img-fluid figure-img" src="../assets/img/oks.png"></figure>
                                        </div>
                                        <div class="col-md-7">
                                            <h4>Lorem Ipsum</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-7 right">
                                            <h4>Lorem Ipsum</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                        <div class="col-md-5">
                                            <figure class="figure"><img class="img-fluid figure-img" src="../assets/img/oks.png"></figure>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade specifications active show" role="tabpanel" id="specifications">
                                    <div class="row">
                                        <div class="col" style="padding-right: 40px;padding-left: 40px; padding-top: 20px;">

                                            <div style="margin: 10px; border-style=solid; border-radius: 6px; color: rgb(198,197,197); border-color=rgb(198,197,197);" v-if="eventType==2 && eventTickets.length==0">Para adquirir este evento debe ser invitado por un promotor/auspiciante o tener un código de acceso.</div>
                                            

                                            <div v-else>


                                        
                                            <div  class="table-responsive" style="text-align: center;font-family: Raleway, sans-serif;">
                                                <table class="table ">
                                                    <thead>
                                                        <tr>
                                                            <th style="color: rgb(255,255,255);">Localidad</th>
                                                            <th style="color: rgb(255,255,255);">Precio</th>
                                                            <th style="color: rgb(255,255,255);">Descripción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(eventTicket, index)  in eventTickets" :key="index" style="color: rgb(198,197,197);" >
                                                            <td>{{eventTicket.name}}</td>
                                                            <td v-if="eventTicket.is_courtesy">Cortesía</td>
                                                            <td v-else>${{eventTicket.price}}</td>
                                                            <td v-if="eventTicket.description==''">Limitadas</td>
                                                            <td v-else>"{{eventTicket.description}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p style="color: rgb(198,197,197);font-family: Raleway, sans-serif; text-align: justify;">NOTA: La disponibilidad de entradas es limitada y es verificada previo a la compra. Se recomienda una compra en el menor tiempo posible para asegurar su acceso al evento. Los códigos de descuento están disponibles hasta agotar stock y por tiempo Limitado.<br></p>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="tab-pane fade" role="tabpanel" id="reviews">
                                    <div class="reviews" style="padding-left: 40px; padding-right: 40px; padding-top: 20px; padding-bottom: 20px;">
                                        <div class="review-item">
                                            <h4 style="color: rgb(255,255,255);">Título de la Novedad</h4><span class="text-muted" style="color: rgb(198,197,197);"><a href="#">John Smith</a>, 20 Jan 2018</span>
                                            <p style="color: rgb(198,197,197);">Novedades del evento se actualizan.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



</template>


<script>

export default {
  
    name: 'EventViewDetail',
    props:{
        updatesProp: {
          type: Object
       },

       eventTickets: {
          type: Array
       },

        eventType: {
      required: true
  },

     },
     data(){
     return{

     }
     

   },

   mounted(){


   },

    created () {
        
  },

  
   methods:{
    
      
   
   }
}
</script>

<style>


.clean-block.clean-product .product-info .info .summary {
  border-top: 1px solid white;
  padding-top: 30px;
}

.clean-block.clean-product .gallery {
  padding: 20px;
  background-color: rgb(165, 157, 157);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}


    
</style>

   