<template>
<div class="container" style="padding-top: 10px">

<div class="row" style="padding-top: 20px">
          <div class="col">
            <div class="card text-light bg-dark">
              <div class="card-body">
                <h4 class="card-title">Alerta Ciudadano</h4>
                <div role="tablist" id="accordion-1">
                  <div
                    class="card border-light"
                    style="background-color: rgb(52, 58, 64)"
                  >
                

                      <div  class="card-body" style="padding-top: 0px">

                        <p class="card-text">Su cuenta se encuentra al momento suspendida por un error en su pago.</p>
                        <button><p class="card-text">Click Aqui para reactivarla</p></button>

                      
                         
                     
                       
                    </div>
                  </div>
                 
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
                </div>

    
</template>>


<script>
export default {
    name: "DisabledAccount"
    
}
</script>


   