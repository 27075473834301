
<template>
<div>


  
<HeaderMain @messageFromHeader="searchEvents"/>

<div>



</div>

<div>

       <SlideShow/>
       <SearchCourse v-if="!main" :courses="search" />

 <body>
  <section class="clean-block clean-product dark" style="background: rgb(41,7,55);   background: radial-gradient(circle,rgb(41,7,55) 20%, rgb(0,0,0) 100%);">
            <div class="container" style="padding-top: 40px; padding-bottom: 40px;">


                <div class="block-content" style="background: rgb(35,35,42); border-radius: 6px; padding: 20px;">
                    <div class="product-info">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="info">
                                    <h3 style="margin-bottom: 15px;color: rgb(255,255,255);font-family: Quicksand, sans-serif;">Entradas Confirmadas</h3>
                                    <h4  style="color: rgb(198,197,197);font-family: Raleway, sans-serif;">Evento: {{event.name}}</h4>
                                    <h6 class="blog-category blog-text-success" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i :class="category.icon"></i>&nbsp;{{category.name}}</h6>
                                    <h6 class="blog-category blog-text-success" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i class="fas fa-map-marker-alt"></i>&nbsp;{{venue.name}}. {{city}}</h6>
                                    <div class="ftr">
                                        <div class="stats" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i class="far fa-clock"></i><span>&nbsp;{{formatDate(event.start_date)}}</span></div>
                                    
                                    <div class="row height d-flex justify-content-center align-items-center">

                      
                      <div style="padding-top: 10px;" class="col-md-8">


                          </div>

                            
                      
                        
                      </div>
                      
                    </div>
                                    <div class="summary">
                                        <p style="color: rgb(255,255,255);font-family: Raleway, sans-serif;">¡Fomo plan activado! Tus accesos han sido enviados al correo registrado y además puedes descargarlos desde el botón de abajo. Recuerda revisar tu carpeta spam o de notificaciones.</p>
                                        
                                        <button style="margin-top: 10px; margin bottom: 10px;" @click="downloadT()" type="button" class="btn btn-primary">Descargar Entradas</button>
                                        
                                        <va-alert @click="link()" style="margin-top: 20px;" v-if="event.id==1" description="¡Este evento tiene una FIESTA DE CIERRE exclusiva el día 6 de Mayo. Click Aquí para adquirir tus entradas!"
  />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                  
                    <div class="clean-related-items"></div>
                </div>
            </div>
  </section>


        <PdfGeneration/>


 </body>

    <footer>
        <FooterMain/>
    </footer>


</div>
</div>

</template>

<script>
// @ is an alias to /src
import { apiService } from "../common/api.services.js";

import HeaderMain from "@/components/HeaderMain.vue"
import FooterMain from "@/components/FooterMain.vue";
import PdfGeneration from "@/components/sales/PdfGeneration.vue";






export default {
  name: "EventView",

   props: {

    ticket: {
      type: String
    },

    id: {
      required: true
    },

   
    //log25
    
  },


  data(){
    return{
      search:[],
      searchField:"",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      showLoading: false,
      event: {},
      venue: {},
      category: {},
      city: "",
      eventTickets: [],
      updates: [],
      host: null,
      code: null,
      next: null,
      errCode: false,
      promoCode: "",
      saleMode: false,
      order_fee: 0,
      order_total:0,
      products:0,
      step: 0,
      name: "",
      last_name: "",
      phone: "",
      email: "",
      cc: "",





     

    };
  },
  components: {
    HeaderMain,
    FooterMain,
    PdfGeneration


  },

 

  created(){
    this.getEvent()
    this.getEventTickets()


  },
  methods:{



      getEvent() {


      // make a GET Request to the questions list endpoint and populate the questions array
      let endpoint = `/api/events/${this.id}/`;
      this.showLoading = true;
      apiService(endpoint).then((data) => {
        this.event = data;
        this.category = data.category
        this.venue = data.venue
        this.city = data.venue.city.name
        this.showLoading = false;
        this.updates = data.updates
        this.event_id = data.id

      });
     
    },


    downloadT(){

       
       window.open(this.$route.query.co);

       

    },

    startSale(){

      this.saleMode=true
      this.step=1

    },

    link(){

          //window.location.replace("https://www.fomoplanes.com/event/fomo-night-uio/?co=fomoupsale2x1+&wanted=true#anchor1");

          window.location.replace("https://www.fomoplanes.com/event/BYE-TOQUE/?co=fomoupsale1");


    },

    getEventTickets() {

    
      this.code = this.$route.query.co



// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = "/api/event-tickets/?event=" + this.id
        if (this.code){
            endpoint = endpoint + "&code=" + this.code 
        }

          if (this.host){
            endpoint = endpoint + "&host=" + this.host 
        }
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);
          this.showLoading = false;
        })
      
        
    },
   



newEventTicketsSearch() {


// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      
          let   endpoint = "/api/event-tickets/?event=" + this.id + "&code=" + this.promoCode 
      
        
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);

          if (data.length==0){
            this.errCode=true
          }else{
             this.errCode=false
             this.code=this.promoCode
          }
          this.showLoading = false;
        })
      
        
    },
   
  startUserCreation(products, total, fee){
    this.products=products
    this.order_total=total
    this.order_fee=fee
    this.step++

  },


  startGuestNames(name, last_name, phone, email, cc){
    this.name=name
    this.last_name=last_name
    this.phone=phone
    this.email=email
    this.cc=cc
    this.step++
  },



          


    formatDate(dateString){
        const objectDate  = new Date(dateString);
        
        const year = objectDate.getFullYear();

const month = String(objectDate.getMonth() + 1).padStart(2, '0');

const day = String(objectDate.getDate()).padStart(2, '0');


        let format = day + "." + month + "." + year
              // Then specify how you want your dates to be formatted
         return format;
    },


  },
 

};
</script>

<style>


.vanilla-zoom {
  width: 100%;
}

.vanilla-zoom .sidebar {
  width: 100%;
  display: flex;
}

.vanilla-zoom .sidebar img.small-preview {
  width: 60px;
  margin-right: 5px;
  cursor: pointer;
  opacity: .5;
}

.vanilla-zoom .sidebar img.small-preview.active, .vanilla-zoom .sidebar img.small-preview:hover {
  opacity: 1;
}

.vanilla-zoom .sidebar img.small-preview:last-child {
  margin-right: 0;
}

.vanilla-zoom .zoomed-image {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.clean-block.clean-product .product-info .info .summary {
  border-top: 1px solid white;
  padding-top: 30px;
}

.clean-block.clean-product .gallery {
  padding: 20px;
  background-color: rgb(165, 157, 157);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}


 .searchcode{
       position: relative;
       box-shadow: 0 0 40px rgba(51, 51, 51, .1);
         
       }
   
       .searchcode .fa-search{

        position: absolute;
        top: 15px;
        left: 16px;

       }

       .searchcode button{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 110px;
        text-align: center;
        vertical-align: middle;
       }

       
.searchcode .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}


    
</style>
