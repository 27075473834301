import { createRouter, createWebHistory  } from 'vue-router'
import HomeMain from "../views/HomeMain.vue";
import EventView from "../views/EventView.vue";
import HostEventView from "../views/HostEventView.vue";
import ConfirmationView from "../views/ConfirmationView.vue";
import PendingView from "../views/PendingView.vue";
import InvitationView from "../views/InvitationView.vue";
import UserTicketView from "../views/UserTicketView.vue";
import PdfGenerationTest from "../components/sales/PdfGenerationTest.vue";
import EventHostView from  "../pages/host/EventHostView.vue";
import EventOperatorView from  "../pages/operator/EventOperatorView.vue";
import TermsView from "../views/TermsView.vue";
import PreloaderComponent from "../components/PreloaderComponent.vue";
import ProccessPayment from "../components/sales/ProccessPayment.vue";




import AdminView from  "../pages/admin/AdminView.vue";





//import PaginaDetalle from "../views/PaginaDetalle.vue"
//import Profile from "../views/Profile.vue"
//import ViewPaginaDetalle from "../views/ViewPaginaDetalle.vue"
//import NextReleases from "../views/NextReleases.vue"
//import Preloader from "../views/Preloader.vue"

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 

if (localStorage.getItem('is_allowed')==true  || localStorage.getItem('is_allowed')=="true"){

  isAuthenticated = true;
}
else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {

  next('/preloader'); // go to '/login';
 }
}




function operatorGuard(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 

if (localStorage.getItem('is_operator')==true  || localStorage.getItem('is_operator')=="true"){

  isAuthenticated = true;
}
else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
   console.log("valido")

  next('/preloader'); // go to '/login';
 }
}




function AdminGuard(to, from, next)
{
 var isAdmin= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 

if (localStorage.getItem('is_admin')==true  || localStorage.getItem('is_admin')=="true"){

  isAdmin = true;
}
else
isAdmin= false;
 if(isAdmin) 
 {
  next(); // allow to enter route
 } 
 else
 {
   console.log("valido")

  next('/preloader'); // go to '/login';
 }
}





function logOutRoute(to, from, next)
{
  window.localStorage.removeItem('id');
  window.localStorage.removeItem('is_admin');
  window.localStorage.removeItem('is_staff');
  window.localStorage.removeItem('is_superuser');
  window.localStorage.removeItem('username');
  window.localStorage.removeItem('is_allowed');
  window.localStorage.removeItem('is_lail');
  window.localStorage.removeItem('is_student');
  window.localStorage.removeItem('is_active_student');
  window.localStorage.removeItem('is_special_case');
  window.localStorage.removeItem('is_active_company');



  next()

}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeMain
  },
  
  {
    path: "/event/:slug?/",
    name: "event",
    component: EventView,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    path: "/event/loading/",
    name: "loading",
    component: PreloaderComponent,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    path: "/host/:host?/",
    name: "host",
    component: HostEventView,
    props:true,
    query: {
      co: 'co'
    }

  },



  
  {
    path: "/success/",
    name: "success",
    component: ProccessPayment,
    props:true,
    query: {
      co: 'co'
    }

  },



  {
    path: "/order-confirmation/:id?",
    name: "confirmation",
    component: ConfirmationView,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    path: "/pdf-confirmation/",
    name: "test-pdf",
    component: PdfGenerationTest,
    props:true,
    query: {
      co: 'co'
    }

  },



  {
    path: "/user-tickets/",
    name: "usertickets",
    component: UserTicketView,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    path: "/order-pending-confirmation/:id?",
    name: "pending-confirmation",
    component: PendingView,
    props:true,
    query: {
      co: 'co'
    }

  },



  {
    path: "/terms/",
    name: "terms",
    component: TermsView,
   

  },

  



  

  {
    name: 'account',
    path: '/account',
    component: () => import('../pages/user/ListAccountView.vue'),
    props: true,
    beforeEnter : guardMyroute,

  },




  {
    path: "/experience/:id?",
    name: "experience",
    component: InvitationView,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    name: 'events',
    path: '/events',
    component: () => import('../pages/host/ListEventView.vue'),
    props: true,
    beforeEnter : guardMyroute,

  },

  {
    name: 'prevents',
    path: '/pr-events',
    component: () => import('../pages/ambassadors/PrListEventView.vue'),
    props: true,
    beforeEnter : guardMyroute,

  },

  {
    path: "/event-host/:slug?",
    name: "EventHostView",
    component: EventHostView,
    beforeEnter : guardMyroute,
    props:true,
    query: {
      co: 'co'
    }

  },


  {
    name: 'event-creation',
    path: '/event/create',
    component: () => import('../pages/host/EventCreationView.vue'),
    beforeEnter : guardMyroute,
  },

  {
    name: 'promo',
    path: '/promo',
    component: () => import('../pages/host/Promotion.vue'),
    beforeEnter : guardMyroute,
  },

  {
    name: 'finances',
    path: '/finances',
    component: () => import('../pages/host/Finance.vue'),
    beforeEnter : guardMyroute,
  },






  {
    name: 'qr-test',
    path: '/qr-test',
    component: () => import('../pages/operator/QrTest.vue'),
    beforeEnter : guardMyroute,
  },


  {
    name: 'qr-reader',
    path: '/qr-reader',
    component: () => import('../pages/operator/QrReader.vue'),
    beforeEnter : operatorGuard,
  },

  {
    name: 'operator-events',
    path: '/operator-events',
    component: () => import('../pages/operator/ListEventView.vue'),
    props: true,
    beforeEnter : operatorGuard,

  },

  {
    path: "/event-operator/:slug?",
    name: "EventOperatorView",
    component: EventOperatorView,
    beforeEnter : operatorGuard,
    props:true,
    query: {
      co: 'co'
    }

  },









  {
    name: 'preloader',
    path: '/preloader',
    props: true,
    component: () => import('../views/PreloaderView.vue'),
  },
  
  {
  name: 'LogOut',
  path: '/logout',
  props: true,
  beforeEnter : logOutRoute,
  component: () => import('../views/SystemLogout.vue'),
},


{
  name: 'venue-test',
  path: '/venue-test',
  component: () => import('../components/sales/VenueMap.vue'),
},



{
  path: "/admins/",
  name: "AdminView",
  component: AdminView,
  beforeEnter : AdminGuard,
  props:true,
 

},



  
]





const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }else if(to.hash) {
        // BEFORE:
        // return { selector: to.hash }
        return { el: to.hash }
      }else {
      return { top: 0 }
    }
  },
  history: createWebHistory(),
  routes
})

export default router




