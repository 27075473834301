<template>

<div class="layout__content1 phoneMess">
        <ul>
          <li style="color:white">Utilice los dos dedos para hacer zoom.</li>
        </ul>
      

       
 </div>

      



    <div class="layout1">
      <div class="layout__map1">
        <div class="card1">
          
          <SvgMap
            :map="map"
            :texts="texts"
            :rects="rects"
            :view-box="viewBox"
            :thin-border-on-zoom="true"
            :wrapper-styles="{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }"
            :mobilePreventScroll="true"
            @click="click"
          />
        </div>
      </div>
      <div class="layout__content1">
        <ul>
          <li style="color:white">Selecciona el o los asientos deseado/s</li>
        </ul>
        <template v-if="selectedRegions && selectedRegions.length">
          <h4 style="color:white" class="small-title1">
            Asientos Seleccionados
          </h4>
          <div
            class="label1"
            style="color: white; border-color: white;"
            v-for="(region, index) in selectedRegions"
            :key="index"
          >
            {{ region.name }}
          </div>


          
          <div v-if="quantity==0">
        <div style="padding-top: 10px;" class="d-flex justify-content-end">
        <button v-if="!loadingSeat" @click="continueSale()" class="btn btn-primary" type="button" style="padding-top: 10px;padding-bottom: 14px;font-family: Quicksand, sans-serif;"><i class="icon-basket"></i>Continuar Compra</button>
          
        <div v-if="loadingSeat">
              <scaling-squares-spinner
                :size="60"
                color="blue"
              />

            </div>


      </div>

       
         </div>

          
        </template>
      </div>
    </div>

    
</template>

<script>
import regions from './regions.js'
import SvgMap from './SvgMap.vue'
import { apiService } from "../../common/api.services.js";
import { ScalingSquaresSpinner   } from 'epic-spinners';


export default {
  name: 'App',
  components: {
    SvgMap,
    ScalingSquaresSpinner  ,

  },


  props:{
       id: {
          type: String
       }, //def si

       quantityM: {
          type: Number
       }, //def si
     },

    



  data () {
    return {
      regions,
      viewBox: '0 0 100 100',
      selectedIds: [],
      selectedTi: [],
      defaultColor: '#ffffe0',
      secondColor: '#ffb947',
      successColor: '#a9d873',
      quantity: 0,
      event: {},
      texts: [],
      rects: [],
      seats: [],
      loadingSeat: false
      
    }
  },

  created(){
    this.quantity=this.quantityM
    this.getEvent()
  },
  computed: {

    
    map () {
      let map = this.seats;
      
      map.forEach(region => {
        if (this.selectedIds.indexOf(region.id) !== -1) {
          region.fill = this.successColor;
        } else {
          region.fill = this.defaultColor;

          var oldDateObj = new Date();
          var mydate = new Date(region.reservation_time);


          if(region.status==0 ||mydate>oldDateObj){
            region.fill=this.secondColor
          }




          
        }
      });

      return map;
    },
    selectedRegions () {

      return this.map.filter(region => this.selectedIds.indexOf(region.id) !== -1);
    }
  },
  methods: {
    findRegionById (id) {
      return this.map.find(x => x.id === id)
    },
    click (payload) {


      if (this.selectedIds.indexOf(payload.id) === -1) {
        if(payload.status!=0 && this.quantity!=0){

        this.selectedIds.push(payload.id)
        var oldDateObj = new Date();
var newDateObj = new Date();
newDateObj.setTime(oldDateObj.getTime() + (30 * 60 * 1000));


        let uProduct =  {id: payload.id, name: payload.name, reservation_time: newDateObj};
        this.selectedTi.push(uProduct)
        this.quantity--
        }

      } else {
        this.selectedIds.splice(this.selectedIds.indexOf(payload.id), 1)
         let objWithIdIndex = this.selectedTi.findIndex((obj) => obj.id === payload.id);
         this.selectedTi.splice(objWithIdIndex, 1); 
        this.quantity++
      }
    
  },

  getEvent() {


// make a GET Request to the questions list endpoint and populate the questions array
let endpoint = `/api/event-tickets-venue/${this.id}/`;
this.showLoading = true;
apiService(endpoint).then((data) => {
  console.log(data)
  this.event = data;
  if (data.venue_section.addons!=null){
    this.rects=data.venue_section.addons
    this.seats=data.venue_section.seats
    this.texts=data.venue_section.texts
  }
});



},


continueSale(){

  this.loadingSeat=true

  console.log("llego a EMIT22")

  let endpoint =  `/api/seat-reservation/`
    apiService(endpoint, "POST", {
    seats: this.selectedTi,
    
   

  })
              .then(data => {
                  if(data.success=="continue"){
                    this.$emit('messagefromVenueMap', this.selectedTi)
                  }else{
                    this.event = "";
                
                 this.rects=[]
                this.seats=[]
              this.texts=[]
              this.selectedTi=[]
              this.selectedIds=[]


                      alert("Estas entradas ya fueron seleccionadas, Intententa una selección distinta");
                      this.loadingSeat=false

                      this.getEvent()

                  }

        
               
              })








  



},




  }
}
</script>

<style>

  .layout1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  .layout__map1 {
    width: 100%;
    margin-bottom: 16px;
  }
  .layout__content1 {
    width: 100%;
  }
  .card1 {
    position: relative;
    padding-bottom: 100%;
    border: 1px solid #dedede;
    border-radius: 18px;
    overflow: hidden;
  }
  .title1 {
    text-align: center;
    margin: 16px 0 32px;
    text-transform: uppercase;
  }
  .small-title1 {
    margin: 0 0 16px;
  }
  .label1 {
    height: 30px;
    line-height: 30px;
    padding: 0 16px;
    border: 1px solid #dedede;
    display: inline-block;
    margin: 0 4px 8px;
    border-radius: 5px;
  }

  .phoneMess{

display: none;

}

  @media (max-width: 768px) {

  .phoneMess{

    display: block;

  }

}
</style>