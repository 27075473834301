<template>
  <div class="ticket-scanner">
    <h2 class="mb">Lector de Entradas QR</h2>

    <div class="center stream">
      <!-- QR Stream Scanner with Overlay Frame -->
      <qr-stream 
        v-if="!success && !loading && !err && !paused" 
        @init="onInit" 
        @decode="onDecode" 
        class="qr-frame mb" 
      >
        <!-- Scanner Frame Overlay -->
        <div class="scanner-overlay">
          <div class="scanner-frame"></div>
        </div>
      </qr-stream>

      <!-- Loading Spinner -->
      <spring-spinner
        v-if="loading"
        :size="60"
        color="blue"
      />

      <!-- Success Message -->
      <va-card v-if="success && !loading">
        <va-card-title>Acceso Confirmado</va-card-title>
        <va-card-content>
          <h1 class="va-h4">¡Bienvenido!</h1>
          <p>Nombre: {{ ticketData.firstName }} {{ ticketData.lastName }}</p>
          <p>Asiento: {{ ticketData.seatName }}</p>
          <p>Localidad: {{ ticketData.productName }}</p>
          <va-button @click="restartSearch">Escanear otra entrada</va-button>
        </va-card-content>
      </va-card>

      <!-- Error Message -->
      <va-card v-if="err && !loading">
        <va-card-title>Acceso Denegado</va-card-title>
        <va-card-content>
          <h1 class="va-h4 error">ERROR: Acceso no permitido</h1>
          <p>{{ errorMessage }}</p>
          <va-button color="danger" @click="restartSearch">Reintentar</va-button>
        </va-card-content>
      </va-card>
    </div>

    <!-- Control Buttons -->
    <div class="controls">
      <va-button v-if="!paused && !loading" @click="pauseScan">Pausar</va-button>
      <va-button v-if="paused" @click="resumeScan">Reanudar</va-button>
      <va-button color="warning" @click="restartSearch">Reiniciar Búsqueda</va-button>
    </div>

    <div class="result">
      Resultado: {{ result }}
    </div>
  </div>
</template>

<script>
import { QrStream } from 'vue3-qr-reader';
import { SpringSpinner } from 'epic-spinners';
import { apiService } from '../../common/api.services.js';

export default {
  components: { QrStream, SpringSpinner },

  props: {
    event: {
      required: true,
    },
  },

  data() {
    return {
      result: '',
      errorMessage: '',
      loading: false,
      success: false,
      err: false,
      ticket: {},
      index: 0,
      paused: false,
      ticketData: {
        firstName: '',
        lastName: '',
        seatName: '',
        productName: '',
      },
      isDecoding: false
    };
  },

  methods: {
    pauseScan() {
      this.paused = true;
    },

    resumeScan() {
      this.paused = false;
    },

    restartSearch() {
      this.result = '';
      this.errorMessage = '';
      this.loading = false;
      this.success = false;
      this.err = false;
      this.paused = false;
      this.ticket = {};
      this.index = 0;
      this.ticketData = {
        firstName: '',
        lastName: '',
        seatName: '',
        productName: '',
      };
      this.isDecoding = false;
    },

    async onDecode(result) {
      if (this.isDecoding) return;
      this.isDecoding = true;
      this.loading = true;
      this.success = false;
      this.err = false;
      this.errorMessage = '';

      const timeout = setTimeout(() => {
        if (this.loading) {
          this.handleError('Tiempo de espera agotado, por favor reintente.');
        }
      }, 10000); // Límite de tiempo de espera de 5 segundos

      try {
        const [invoice, index] = result.split('hash');
        this.index = index;

        const data = await apiService(`/api/invoices/${invoice}/`);
        clearTimeout(timeout);

        if (data) {
          this.ticket = data;
          if (this.ticket.invoice_items[this.index]?.status) {
            this.secondValidation(this.ticket.invoice_items[this.index]);
          } else {
            this.handleError('Entrada inválida.');
          }
        } else {
          this.handleError('No se ha encontrado la entrada.');
        }
      } catch (error) {
        clearTimeout(timeout);
        this.handleError('Error al cargar los datos de la entrada.');
      } finally {
        this.isDecoding = false;
      }
    },

    async secondValidation(invoiceItem) {
      const endpoint = `/api/update-invoice-item/${this.event.id}/${invoiceItem.id}/`;

      try {
        const data = await apiService(endpoint);
        if (data === 'Entrada Procesada') {
          this.success = true;
          this.ticketData = {
            firstName: invoiceItem.first_name,
            lastName: invoiceItem.last_name,
            seatName: invoiceItem.seat_name,
            productName: invoiceItem.product_name,
          };
        } else {
          this.handleError('La entrada ya fue utilizada o no pertenece al evento.');
        }
      } catch (error) {
        this.handleError('Error al procesar la validación de la entrada.');
      } finally {
        this.loading = false;
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        this.handleError(this.getCameraError(error.name));
      }
    },

    handleError(message) {
      this.errorMessage = message;
      this.err = true;
      this.loading = false;
    },

    getCameraError(errorName) {
      const errors = {
        NotAllowedError: "ERROR: Debe otorgar permiso para acceder a la cámara.",
        NotFoundError: "ERROR: No se encontró una cámara en este dispositivo.",
        NotSupportedError: "ERROR: Requiere un contexto seguro (HTTPS o localhost).",
        NotReadableError: "ERROR: La cámara ya está en uso.",
        OverconstrainedError: "ERROR: Las cámaras instaladas no son adecuadas.",
        StreamApiNotSupportedError: "ERROR: El navegador no soporta la API de transmisión.",
        InsecureContextError: "ERROR: El acceso a la cámara solo se permite en un contexto seguro.",
      };
      return errors[errorName] || `ERROR: Error de cámara (${errorName}).`;
    },
  },
};
</script>

<style scoped>
.ticket-scanner {
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.stream {
  max-height: 400px;
  max-width: 400px;
  margin: auto;
  position: relative;
}

.controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.result {
  margin-top: 15px;
}

.error {
  font-weight: bold;
  color: red;
}

/* Scanner Overlay Styles */
.scanner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner-frame {
  border: 3px solid #00FF00;
  height: 150px;
  width: 150px;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.6);
  animation: scanner 2s infinite;
}

@keyframes scanner {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
</style>
