<template>
  <div class="conatiner" style="border-color: transparent !important;">
        <div class="border2">
            <div class="qrcode"></div>
            
        </div>
        <div class="border2">
        <p style="color: white;">Cargando...No cierre está Página</p>
                </div>


    </div>
</template>

<script>
export default {
  name: "SpinnerSec"
};
</script>

<style>

.conatiner .border2{
    position: relative;
    width: 100%;
    height: 100%;
    background: url(https://gingopolisdjango.blob.core.windows.net/media/border.png);
    object-fit: cover;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.conatiner .border2 .qrcode{
    position: relative;
    width: 80px;
    height: 80px;
    background: url(https://gingopolisdjango.blob.core.windows.net/media/qrcode1.png);
    object-fit: cover;
    background-position: center;
    background-size: cover;
}
.conatiner .border2 .qrcode::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(https://gingopolisdjango.blob.core.windows.net/media/qrcode2.png);
    background-size: 80px;
    overflow: hidden;
    animation: animImg 4s linear infinite;
}
@keyframes animImg{
    0%,
    100%{
        height: 18px;
    }
    50%{
        height: calc(100% - 18px);
    }
}
.conatiner .border2 .qrcode::after{
    content: '';
    position: absolute;
    inset: 18px;
    width: calc(100% - 40px);
    height: 2px;
    background: #80e1dd;
    filter: drop-shadow(0 0 30px #80e1dd) drop-shadow(0 0 60px #80e1dd) drop-shadow(0 0 100px #80e1dd);
    animation: animLine 4s linear infinite;
}
@keyframes animLine{
    0%,
    100%{
        top: 18px;
    }
    50%{
        top: calc(100% - 18px);
    }
}
</style>
