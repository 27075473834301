<template>


 <section>


    <div v-if="ticketsApp==true">
     
    <PdfGeneration  @messageFromPdfGeneration="afterPdf" :event="event" :ticketsTable="ticketsM" :invoice="invoice" />  
  </div>

   <div v-if="ticketsApp==true">  
    <PreloaderComponent />  
   </div>


  </section>

  

    
</template>


<script>
import { apiService } from "../../common/api.services.js";
import { apiServiceForm } from "../../common/api.services.js";

import PdfGeneration from "@/components/sales/PdfGeneration.vue";
import PreloaderComponent from "@/components/PreloaderComponent.vue";





const data = new FormData()

export default {
  
    name: 'CustomerData',

    components: {
      PreloaderComponent,
    PdfGeneration
  },





    props:{

      customer_prop: {
          type: Object
       },


        ticketsTable: {
          type: Object
       },
       subtotalProp: {
          type: Number
       },
       totalProp: {
          type: Number
       },
       feeProp: {
          type: Number
       },
       posProp: {
          type: Number
       },

       first_name: {
          type: String
       },

       last_name: {
          type: String
       },
      
       email_prop: {
          type: String
       },
       phone_prop:{
         type: String
       },

       code:{
         required: false
       },


    
     },

     

     data(){
     return{
       email: "",
       cc:"",
       next:null,
       availableTickets:[],
       order:[],
       password: "",
       customer_id: 0,
       require_account: false,
       debounceFn: null,
       initialSearch: false,
       invoice: 0,
       payment_case: false,
       bankAccount: false,
       posAccount: false,
       value: 'fomoaut',
       url_number: 'fomoaut',
       comment:'fomoaut',
       p_status: 1,
       payment_type: 3,
       final_file:null,
       generated_invoice: {},
       generated_tickets: [],
       codeF: "",
       ticketsApp: false,
       loadingMain: false,
       automatic_sale: false, 
       phone: "",
       cryptoAccount: false,
       showbank: false,
       showbank1ec: false,
       showcash: false,
       showpos: false,
       nuvei_reference: "",
       nuveiAccount: false,
       proc: 1, //type of sale if is nromal of has a promo,
       newTotal: 0,
       newFee: 0,
       showMethods: true,
       f_phone: "",
       f_address: "",
       showPagoPlux: false,
       showPaypal: false,
       appSale: false,
       pos: 0,
      total:0.00,
      fee: 0.00,
      subtotal:0,
      bankAccount1Ec: false,
      reload: true,
      bank_fee: 0,
      revenue_fee:0,
      pname: "",
      isReady: true,
      ticketsM: [],
      indexProm: 0,
      showMpAr: false,
      mp_reference: "",
      currency: "USD",
      event: {}
      



      
     }
     

   },

    created () {
      this.startSaleTwoStep(5)
   
  },


  
   methods:{


    reloadCasePp(rel){
      this.reload=rel

    },


    specialCal1Ec(){
      let coordinated_service=parseFloat(this.event.coordinated_service)

      this.newFee=(coordinated_service * this.ticketsTable.length)
      let subtotal1=parseFloat(this.subtotal) 
      this.newTotal=(subtotal1+this.newFee)
      this.newTotal=this.newTotal.toFixed(2)
      this.newFee=this.newFee.toFixed(2)
      this.proc=3
    },



    specialCal(){
      
      if (this.event.id==106 || this.event.id==108 || this.event.id==105){

        let coordinated_service=parseFloat(this.event.coordinated_service)


        this.newFee=(coordinated_service * this.ticketsTable.length)

      }else{
        this.newFee=parseFloat(0) 

      }
      let subtotal1=parseFloat(this.subtotal) 
      this.newTotal=(subtotal1+this.newFee)
      this.newTotal=this.newTotal.toFixed(2)
      this.newFee=this.newFee.toFixed(2)
      this.proc=3

    },

    returnFromBank(){
      this.bankAccount=false
      this.reload=false
      this.proc=1
      this.showMethods=true

    },


    returnFromBank1Ec(){
      this.reload=false
      this.bankAccount1Ec=false
      this.proc=1
      this.showMethods=true

    },


    returnFromNuvei(){
      this.nuveiAccount=false
      this.proc=1
      this.showMethods=true

    },


    returnFromPos(){
      this.posAccount=false
      this.proc=1
      this.showMethods=true


    },


    nuveiCase(){
      this.showMethods=false


    this.payment_options=false //Variable que significa que carga

  let endpoint = `/api/nuvei/generate-reference/?id=${this.customer_id}&amount=${this.total}`; //open pos
apiService(endpoint).then(data => {

  this.nuvei_reference=data.reference
  this.nuveiAccount=true
});




    },

         showBankButton(){
          let payments = this.event.payment_options

          for (let index = 0; index < payments.length; index++) {
            
            if (payments[index].id==2){
              this.showbank= true

            }else if (payments[index].id==4){
              this.showbank1ec=true

            }
            
          }

          

         },



         showCashButton(){

          const queryString = window.location.search;

          const urlParams = new URLSearchParams(queryString);

          const cash = urlParams.get('cash')

          if(cash=="f0mo1" || cash=="f0mo2" || cash=="f0mo3" || cash=="f0mo4"){
            this.showcash=true
          }
         },


         showPagoPluxButton(){
          let payments = this.event.payment_options

          for (let index = 0; index < payments.length; index++) {
            
            if (payments[index].id==3 || payments[index].id==5){
              this.showPagoPlux= true
              this.pname=payments[index].name
            }

            
            if (payments[index].id==1){
              this.showPaypal= true

            }

            if (payments[index].id==6){
              
    this.payment_options=false //Variable que significa que carga

let endpoint = `/api/mercadopago/generate-reference/?amount=${this.total}`; //open pos


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();


today = yyyy + '-' + mm + '-' + dd;



let ifee = this.fee
let itotal=this.total


if (this.proc==3){
          ifee = this.newFee
         itotal=this.newTotal


}
            
            apiService(endpoint, "POST", {
            invoice_items: this.ticketsM,
            invoice_payments: [],
            invoice_payment_terms: [],
            status: 3, //created
           // discount: this.discount,
            discount: 0,
            starting_date: today,
            customer: this.customer_id,
            pos: this.pos,
            subtotal:  this.subtotal,
            taxes : 0,
            total: itotal,
            fee : ifee
            }).then(data => {

this.mp_reference=data.mp.id
this.showMpAr= true

});




            }
            
          }

         },


         showPosButton(){
          const queryString = window.location.search;

          const urlParams = new URLSearchParams(queryString);

          const pos = urlParams.get('pos')

          if(pos=="f0mo1" || pos=="f0mo2" || pos=="f0mo3" || pos=="f0mo4"){
            this.showpos=true
          }

         },



         automaticSale(){

           this.customer_id=this.customer_prop.id

           //corregir
           if(this.event.id==16){
            this.customer_id=1

           }


           if (this.subtotal==0.00 || this.subtotal=="0.00" || this.subtotal==0){
             this.automatic_sale=true

              this.startSale(true)
           }else{
            //lo que se hace en pasarelas de pago
            this.getNuveiReference()
           }

         },





appSaleMobile(){


  if (this.appSale!=null || this.appSale!=undefined)


  this.customer_id=this.$route.query.customer_id,
  this.pos=this.$route.query.pos,
  this.subtotal=this.$route.query.subtotal
  this.total=this.$route.query.total
  this.fee=this.$route.query.fee




  //Get 

},




         generateReport (i) {
           console.log(i)
            this.codeF="MEEE"
            console.log("beforePDF")
            console.log(this.codeF)
            
            this.$refs.html2Pdf.generatePdf()
            
        },

        hasDownloaded($event){
            console.log("meee")
            console.log($event)

            this.final_file = new File([$event], 'ticket.pdf');
            this.createPayment()
        },


getNuveiReference(){
let endpoint = `/api/nuvei/generate-reference/?id=${this.customer_id}&amount=${this.total}`; //open pos
apiService(endpoint).then(data => {
  console.log(data)
});

},

  
    getCustomer(){
      let endpoint = `/api/contacts/?email=${this.email}`; //open pos


      console.log(endpoint)

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
                
      apiService(endpoint).then(data => {
        if (data.length==0){
          this.require_account=true
        
        }else{
          this.customer_id = data[0].id;
          this.require_account=false
        }
        this.initialSearch=true
      });
    
    }, 1250)
  },


   getAutoCustomer(){
      let endpoint = `/api/contacts/?email=${this.email}`; //open pos
      this.loadingMain=true
      this.automatic_sale=true

        apiService(endpoint).then(data => {
        if (data.length==0){
          console.log("llegue caso1")
          this.require_account=true
          this.startSale(true)

        
        }else{
          console.log("llegue caso2")
          console.log(data[0].id)
          console.log(".............")
          this.customer_id = data[0].id;
          this.require_account=false
          this.startSale(true)
        }
      });
  },





   startSale(validator){

    this.payment_case=validator
    this.loadingMain=true

    if (this.automatic_sale){
      this.isReady=false
      let tes=this.indexProm
      if (tes+1==this.ticketsTable.length){
          this.isReady=true;
        }
        this.ticketsM=[this.ticketsTable[this.indexProm]]
         this.createInvoice() 
      
    }else{
      this.ticketsM=this.ticketsTable

      this.createInvoice()


    }


    

  },




startSaleBankCase(file){
data.append('file', file.files[0])
 this.payment_case=true
 this.payment_type=2
 this.startSale(true)

},




  
  startSalePaypalCase(tran_id){
    this.payment_case=true
    this.url_number=tran_id
    this.bank_fee=(parseFloat(this.total)*0.054)+0.30
    this.bank_fee=this.bank_fee.toFixed(2)
    this.startSale(true)

  },


    
  startSalePosCase(authorization_code){
    this.showMethods=false

 this.payment_case=true
 this.url_number=authorization_code
 this.payment_type=5
 this.bank_fee= (parseFloat(this.total)*0.0448)
 this.startSale(true)

},



  startSaleNuvei(validator, id, authorization_code){


    console.log("llegue a nuvei")

 this.payment_case=validator

 this.nuveiAccount=false

 this.value=id
 this.url_number=authorization_code

 this.loadingMain=true


 this.startSale(true)

},


startSalePagoPlux(validator, id, authorization_code, type){


console.log("llegue a PagoPlux")
this.payment_case=validator
this.value=id
this.url_number=authorization_code
this.loadingMain=true
let dataF=0

if (type==1){
   dataF=(parseFloat(this.total)*0.005)
   this.bank_fee= (parseFloat(this.total)*0.0225)+dataF + (dataF*0.15)
}else if(type==2){
  dataF=(parseFloat(this.total)*0.015)
   this.bank_fee= (parseFloat(this.total)*0.0448)+ dataF + (dataF*0.15)

}else if(type==5){//  MP Argentina
  dataF=(parseFloat(this.total)*0.015)
   this.bank_fee= (parseFloat(this.total)*0.06)
   this.currency="AR"


}else{
  this.bank_fee=0
}
this.startSale(true)
},





startSaleTwoStep(type){

  const params = new URLSearchParams(window.location.search);

// Acceder a un parámetro específico
const payment_id	 = params.get('payment_id	'); // "electronics"
const external_reference		 = params.get('external_reference'); // "electronics"


const parts = external_reference.split('-');

// Asignar las partes a variables separadas
const firstPart = parts[0]; // "124"

const secondPart1 = parts[1]; // "4"

let secondPart2=secondPart1.split('.');

const secondPart = secondPart2[0]; // "4"


let endpoint = `/api/invoices/${firstPart}/`; //open pos
apiService(endpoint).then(data => {
  console.log(data)
  this.invoice=data.id
  this.ticketsM=data.invoice_items
  this.pos=data.pos
  this.value=payment_id
  this.url_number=payment_id
  this.subtotal=data.subtotal
  this.total=data.total
  this.fee=data.fee

  
let endpoint2 = `/api/events-basic/${secondPart}/`; //open pos
apiService(endpoint2).then(data => {
  console.log(data)
  this.event=data
  
if(type==5){//  MP Argentina
   this.bank_fee= (parseFloat(this.total)*0.06)
   this.currency="AR"
   
}else{
  this.bank_fee=0
}


this.ticketsApp=true
});




});






},






  CashCase(){

    



 if (confirm('Confirmar Pago en Efectivo')) {
  this.payment_case=true
 this.payment_type=1
 this.startSale(true)
} else {
  // Do nothing!
  console.log('Thing was not saved to the database.');
}

},



  bankAccountCase(){
    this.showMethods=false
    this.bankAccount=true
  },

  bankAccountCase1Ec(){
    this.showMethods=false
    this.bankAccount1Ec=true
  },


  posAccountCase(){
    this.showMethods=true

    this.posAccount=true
  },



  cryptoCase(){
    this.showMethods=false

    this.cryptoAccount=true
  },


  
afterPdf(file){
  this.final_file=file;
  console.log(this.file)
  this.createPayment()


},


print() {


  let orders = []

  for (let index = 0; index < this.ticketsTable.length; index++) {
    orders.push( this.ticketsTable[index].name)
    
  }
    
    var yourDOCTYPE = "<!DOCTYPE html>"; 
    var printPreview = window.open('', 'print_preview');
    var printDocument = printPreview.document;
    printDocument.open();
    var head =
        "<head>" +
        "<style> .to-print{height:279mm; width:80mm; } </style>" +
        "</head>";
    printDocument.write(yourDOCTYPE +
            "<html>" +
            head +
            "<body>" +
             "<div class='to-print'>" +
             "<p>FOMOPLANES</p>"+
             "<p>Pedido:</p>"+
             "<li>"+ orders + "</li>" +
            "<p>.</p>"+
             "<p>.</p>"+
            "<p>.</p>"+
             "<p>.</p>"+
              "<p>.</p>"+
             "</div>"+
            "</body>" +
            "</html>");
    printPreview.print();
    printPreview.close()
    
 

  window.location.replace("https://www.fomoplanes.com/event/caja-fomo1/?wanted=true")

},


  continueSale(){
      this.$emit('messageFromCustomerData', this.name, this.last_name, this.phone, this.email, this.cc)
    },
 
    createPayment() {

      this.loading=true
        let  endpoint =  `/api/guest-p/?tran_id=fomoaut`
        
        data.append('invoice', this.invoice)
        data.append('point_of_sale', this.pos)
        data.append('url', this.value)
        data.append('url_number', this.url_number)
        data.append('comment', this.comment)
        data.append('subtotal', this.subtotal)
        data.append('currency', this.currency)

        if (this.proc==3){
          data.append('fee', this.newFee)
          data.append('amount', this.newTotal)


        }else{
          data.append('fee', this.fee)
          data.append('amount', this.total)

        }
       
        if (this.payment_type==2){
        this.p_status=0
        this.revenue_fee=this.fee
        }else{
          this.revenue_fee=parseFloat(this.fee)-parseFloat(this.bank_fee)
          this.revenue_fee=this.revenue_fee.toFixed(2)
        }

        if(this.event.id==119  || this.event.id==127){
            this.p_status=0
            this.payment_type=2
           }

           this.bank_fee=parseFloat(this.bank_fee).toFixed(2)


           data.append('bank_fee', this.bank_fee)
           data.append('revenue_fee', this.revenue_fee)

        
        data.append('ticket', this.final_file)
        data.append('payment_type', this.payment_type)
        data.append('status', this.p_status)
        
        apiServiceForm(endpoint, "POST", data)
              .then(data => {
                this.loadingMain=false
                console.log("pago creado")
                console.log(data)


          let tck = data.ticket

        if (this.event.id!=17){// decia 16
          
          if(this.isReady){
            this.$router.push({ name: "confirmation", params: { id: this.event.slug}, query: { co: tck } });
        }else{
          this.indexProm=this.indexProm+1
          this.ticketsApp=false
          this.startSale(true)
        }
        
      }


    
              }).catch(error => {

          alert("Error al ingresar el pago. Por favor comunícate con nosotros para revisar el caso.")
          console.log("error pago:")
          console.log(error)
          //alert('Ha ocurrido un ERROR al actualizar el perfil. Inténtelo nuevamente.  SUDEN - CURSOS CON BOLSA DE EMPLEO')
          //window.location.href = "https://www.somossuden.com/inicio/student-registration.html";


        })
        
       //Ver si el usuario tiene cursos adicionales...
      // if (this.event.id==16){
      //  this.print()

        
      // }

       //Cambiar contraseña y estado del usuario a activado...


      },

       getCode(index, invoice){
        return invoice + "hash" + index;
    },



    





      






    
    
   }
}
</script>

<style scoped>

    
</style>

   