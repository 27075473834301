<template>

<div id="paymentBrick_container">
</div>

</template>




<script>
import { apiService } from "../../common/api.services.js";

export default {
  name: 'VueMp',
  async mounted() {
    const that = this

    console.log("buscamos total")
    console.log(this.total)

    console.log("buscamos ref")
    console.log(this.reference)

    const mp = new window.MercadoPago('APP_USR-98db94b4-6171-4317-9aab-2926c2110fe9', {
    locale: 'es'
  });
  const bricksBuilder = mp.bricks();
    const renderPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          /*
            "amount" es el monto total a pagar por todos los medios de pago con excepción de la Cuenta de Mercado Pago y Cuotas sin tarjeta de crédito, las cuales tienen su valor de procesamiento determinado en el backend a través del "preferenceId"
          */
          amount: this.cartTotal,
          preferenceId: this.reference , 
          payer: {
            firstName: "",
            lastName: "",
            email: "",
          },
        },
        customization: {
          visual: {
            style: {
              theme: "dark",
              customVariables: {
              baseColor: "#007BFF",
              buttonTextColor: "white",

                },
            },
            
          },
          paymentMethods: {
            creditCard: "all",
										debitCard: "all",
										bankTransfer: "all",
										mercadoPago: ['wallet_purchase'],
                    maxInstallments: 1
          },
        },
        callbacks: {
          onReady: () => {
            /*
             Callback llamado cuando el Brick está listo.
             Aquí puede ocultar cargamentos de su sitio, por ejemplo.
            */
          },
          onSubmit: ({ selectedPaymentMethod, formData }) => {
            console.log(selectedPaymentMethod)
            // callback llamado al hacer clic en el botón de envío de datos
            return new Promise((resolve, reject) => {
              let endpoint="/api/mercadopago/create/"
              apiService(endpoint, "POST", formData)
            .then(data => {
              console.log(data)
              if(data.success){


                that.$emit('messageFromMp', true, data.success.id, data.success.id, 5)


                
              }else{
                alert("Ha ocurrido un error con el pago, intente nuevamente.")
                reject();

              }


              resolve()

            }
            )
                .catch((error) => {
                  console.log(error)
                  // manejar la respuesta de error al intentar crear el pago
                  reject();
                });
            });
          },
          onError: (error) => {
            // callback llamado para todos los casos de error de Brick
            console.error(error);
          },
        },
      };
      window.paymentBrickController = await bricksBuilder.create(
        "payment",
        "paymentBrick_container",
        settings
      );
    };
    renderPaymentBrick(bricksBuilder);

    
  },
  data() {
    return {
      paid: false,
    };
  },
  props: {
    cartTotal: {
      type: Number,
    },
    reference: {
      type: Number,
    },
  },

};
</script>



<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>