
<template>
  <HeaderMain @messageFromHeader="searchEvents" />


  <body
    style="background: rgb(10,2,49);   background: radial-gradient(circle,rgb(10,2,49) 20%, rgb(0,0,0) 100%); color: white; !important">
    import FooterMain from "@/components/FooterMain.vue";

    <div class="container" style="text-align: justify; padding: 40px;">

      <h1 style="color: white"><strong>Términos y Condiciones</strong></h1>

      <p style="color: white">Los presentes Términos y Condiciones aplican a todas las versiones del sitio web que
        expresamente contengan un enlace a los Términos y que sean de titularidad de FOMOPLANES. Al visitar el sitio web y
        registrarse en cualquier lugar que contenga un enlace a estos Términos, usted se compromete a aceptar y quedar
        obligado bajo estos Términos, los cuales quedan sujetos a eventuales modificaciones. El cliente aceptará
        irrefutablemente los siguientes términos y condiciones de la compra online entradas en sitios web o apps de
        FOMOPLANES
        El término “Usuario” aplica a, Usuario web o de aplicaciones. En calidad de Usuario usted debe leer detenidamente
        el contenido del presente documento a fin de comprender los términos y condiciones que rigen el uso de las
        diferentes versiones de las aplicaciones y del sitio web. El mismo contiene información necesaria e importante
        respecto a sus derechos y obligaciones. Toda la información de carácter personal que el Usuario entregue a
        FOMOPLANES para el registro y el uso de los Sitios, como nombre de usuario, cédula, dirección de correo
        electrónico, número de contacto telefónico, etc., Será usada para fines propios del servicio y no será utilizada
        para la venta a terceros y reconoce que son auténticos y reales.
        Los eventos expuestos en FOMOPLANES (www.fomoplanes.com. no son responsabilidad ni organizados por FOMOPLANES, la
        misma empresa se encarga de comercialización, promoción y exposición de las entradas a espectáculos públicos y
        privados. El organizador (Empresario, productor) es el único responsable de la realización correcta del evento, el
        mismo ha pasado por un proceso de verificación. Solo en los casos que se mencione un evento como “Fomo Signature”,
        este será organizado/coorganizado por FOMOPLANES.
        FOMOPLANES garantiza que toda la información personal proporcionada por el Usuario o toda aquella información que
        permite identificar o individualizar al Usuario se encuentra protegida por la Política de Privacidad de la
        compañía y está autorizado para dar usos comerciales sin afectar en ningún caso la intimidad y seguridad de los
        usuarios.
      </p>

      <p style="color: white">Al usar los servicios provistos por FOMOPLANES o realizar una compra, usted expresamente
        acepta y acuerda que se almacenarán sus datos personales (No se almacenarán datos de Tarjetas de crédito ni
        débito) y que FOMOPLANES enviará el recibo de su compra junto con sus boletos digitales al correo electrónico
        introducido por usted mismo.</p>


        <p style="color: white">Todo el contenido utilizado en estas aplicaciones, tales como el código fuente, secuencias de comandos, texto,
        gráficos, logos, iconos de botones, imágenes, video o programas creados para ofrecer aplicaciones móviles y audio
        (en conjunto, los “Assets”), son de propiedad de FOMOPLANES. o de sus respectivos propietarios y goza de la
        protección de las leyes de derechos de autor de Ecuador y otros países. Todos los programas utilizados en este
        sitio son de propiedad de FOMOPLANES. o de sus respectivos dueños, y gozan de la protección de las leyes de
        derechos de autor de Estados Unidos de América y otros países.
        El cliente acepta el “fee” o “tasa de pago” incurrida en cada compra realizada en el portal www.fomoplanes.com o
        allegados. Misma que dependerá del acuerdo que se llegue con cada promotor de eventos.</p>

      <p style="color: white">FOMOPLANES puede inhabilitar boletos digitales si existe alguna disputa o sospecha de que el
        consumo fue realizado de manera fraudulenta, dejando sin validez los boletos y sin acceso al usuario al evento.
        El usuario se hace enteramente responsable de elegir correctamente hora, fecha, función y localidad correcta en su
        compra. FOMOPLANES no se hace responsable ni garantiza cambios por errores de este tipo.</p>


      <p style="color: white">En caso de compras erróneas, FOMOPLANES. no hará devoluciones de ningún tipo al cliente ya
        que el mismo acepta tácitamente que verificó correctamente los datos de su compra previamente a realizarla.
        En caso de pérdida de clave o nombre de usuario, el usuario siempre podrá recuperar su clave para poder volver a
        ingresar a su cuenta, en caso de no poder ingresar deberá ponerse en contacto con FOMOPLANES. Todos las entradas
        QR o físicas son entera responsabilidad del cliente.
        No existen cambios, devoluciones ni reembolsos ni de la “tasa de pago” ni del valor de los boletos.</p>

      <p style="color: white">En caso de cancelación de un evento, el único encargado de realizar las devoluciones es el
        organizador. En caso de haberse acordado previamente con el organizador, FOMOPLANES realizará las devoluciones
        respectivas y el cliente final deberá seguir los pasos expuestos por las redes sociales o correos electrónicos de
        FOMOPLANES y/o el organizador. En caso de ser necesario canje de entradas, el usuario se acercará al punto de
        encuentro expuesto previamente en redes sociales y correo para canjear sus entradas digitales, en caso de no
        hacerlo; no se harán devoluciones de ningún tipo.
        Las compras efectuadas en la página web www.fomoplanes.com están protegidas por los términos de la legislación
        ecuatoriana, en particular la ley de Comercio Electrónico y su Reglamento, por lo que la contratación y la firma
        electrónica se reputa válida y obliga al usuario, en el términos legales y contractuales del negocio jurídico
        realizado.
        Cualquier diferencia surgida entre las partes será dirimida en derecho aplicando la legislación ecuatoriana, ante
        la justicia ordinaria.</p>


        <p style="color: white">No se realizará la devolución de la entrada cuando la cancelación del evento sea por cuestiones de fuerza mayor.
        Por Fuerza Mayor se entiende cualquier acto, evento, omisión o accidente que se encuentre fuera del control del
        personal de FOMOPLANES y/o promotores; incluye los siguientes: huelgas, motines, ataques terroristas, guerras,
        incendios, inundaciones, terremotos o cualquier otro desastre natural; fallos en las redes de comunicación y/o
        internet, disposiciones legales o legislación, regulación o restricciones gubernamentales, malas condiciones
        climatológicas, cierre del espacio aéreo ecuatoriano, bloqueo de carreteras que impidan el normal funcionamiento
        del tráfico, desastres naturales u otras condiciones fortuitas que no permitan el normal y seguro desenvolvimiento
        de un evento público.</p>
        
        <p style="color: white">Cuando hay un cambio de fecha en un evento, FOMOPLANES gestionará para que las entradas compradas en nuestra
        plataforma sean válidas para la nueva fecha sin ninguna acción adicional por parte del asistente.
        Cuando se cancela un evento, y no hay cambio de fecha, Fomoplanes luego del consentimiento escrito del promotor u
        organizador ofrecerá la devolución de la entrada del valor del ticket, no se incluye en la devolución los gastos
        de servicio de FOMOPLANES. Cuando en un festival o evento de varios se cancela uno o varios días, pero no todos los
        días de dicho evento, se realizará una devolución que corresponda a los días cancelados</p>


        <p>Las compras realizadas en FOMOPLANES cuentan con garantías para el comprador siguiendo la la Ley Orgánica de Defensa del Consumidor y Ley de Comercio Electrónico, Firmas y Mensajes de Datos. Adicionalmente, a todas las garantías dadas por las distintas pasarelas de pago. Dichos.</p>


      <p style="color: white">Al visitar este Sitio, usted acepta que estos Términos y las controversias de cualquier
        índole que surgen entre nosotros se regirán por las leyes de la República del Ecuador.</p>

      <p style="color: white">Al aceptar estos términos y condiciones usted también acepta expresamente adherirse y
        cumplir con los términos de Google, que se encuentran disponibles en http://www.google.com/policies/privacy.</p>

    </div>




  </body>

  <FooterMain />
</template>

<script>
// @ is an alias to /src


import HeaderMain from "@/components/HeaderMain.vue"
import FooterMain from "@/components/FooterMain.vue";








export default {
  name: "TermsView",

  props: {
    id: {
      required: true
    }
    //log25

  },

  components: {
    HeaderMain,
    FooterMain,


  },


  data() {
    return {
      search: [],
      searchField: "",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      showLoading: false,
      event: {},
      venue: {},
      category: {},
      city: "",
      eventTickets: [],
      updates: [],
      host: null,
      code: null,
      next: null,
      errCode: false,
      promoCode: "",
      saleMode: false,
      order_fee: 0,
      order_total: 0,
      products: 0,
      step: 0,
      name: "",
      last_name: "",
      phone: "",
      email: "",
      cc: "",







    };
  },


  created() {


  },



};
</script>

<style>
.register-photo {
  background: #f1f7fc;
  padding: 80px 0;
}

.register-photo .image-holder {
  display: table-cell;
  width: auto;
  background: url(../assets/img/oks.png);
  background-size: cover;
}

.register-photo .form-container {
  display: table;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.register-photo form {
  display: table-cell;
  width: 400px;
  background-color: #ffffff;
  padding: 40px 60px;
  color: #505e6c;
}

@media (max-width:991px) {
  .register-photo form {
    padding: 40px;
  }
}

.register-photo form h2 {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.register-photo form .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}

.register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}

.register-photo form .btn-primary {
  background: #f4476b;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 35px;
  text-shadow: none;
  outline: none !important;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
  background: #eb3b60;
}

.register-photo form .btn-primary:active {
  transform: translateY(1px);
}

.register-photo form .already {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}</style>
