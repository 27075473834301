<template>
  <div id="nav">
  </div>
  <router-view />
</template>

<script>
import { apiService } from '@/common/api.services';


export default {
  name: "App",
  components: {
  },

  mounted(){
    require('@/assets/js/theme.js')
  },
  methods: {


     setUserInfo(){
      let endpoint = `/api/user/`; //open pos
      this.error = ""
      console.log(endpoint)
      apiService(endpoint).then(data => {
      const requestUser = data["id"]; //obtengo el username
      const requestName = data["first_name"]; //obtengo el username
      const requestLastName = data["last_name"]; //obtengo el username
      const Complete = requestName + " " + requestLastName
      const is_admin = data["is_admin"]; //obtengo el username
      const is_superUser = data["is_superuser"];
      const is_host = data["is_promoter"];
      const is_staff = data["is_host"];

      const is_staffs = data["is_staff"];


      
      const is_lail = data["is_lail"];
      const is_student = data["is_student"];
      const is_operator = data["is_operator"];
      const is_company = data["is_company"];

     //corregir al nuevo método más seguro
      if (is_admin==true || is_superUser == true || is_staff == true || is_lail == true | is_host == true){
        window.localStorage.setItem("is_allowed", true)

      }else{
        window.localStorage.setItem("is_allowed", false)

      }
   
      window.localStorage.setItem("id", requestUser) //username es key, y lo otro es el value //guardo el usuario
      window.localStorage.setItem("username", Complete) //username es key, y lo otro es el value //guardo el usuario
      window.localStorage.setItem("is_superuser", is_superUser)
      window.localStorage.setItem("is_student", is_student)
      window.localStorage.setItem("is_staff", is_staff)


      if (is_student){
        const is_special_case = data.contact_profile.is_special_case;
        const is_active_student = data.contact_profile.is_active_student;
        window.localStorage.setItem("is_special_case", is_special_case)
        window.localStorage.setItem("is_active_student", is_active_student)
      }

      //Nuevo método

      if (is_operator || is_operator=="true"){
      window.localStorage.setItem("is_operator", true)
      }else{
        window.localStorage.setItem("is_operator", false)

      }



      if (is_admin || is_admin=="true"){
      window.localStorage.setItem("is_admin", true)
      }else{
        window.localStorage.setItem("is_admin", false)

      }


       if (is_staffs || is_staffs=="true"){
      window.localStorage.setItem("is_staff", true)
      }else{
        window.localStorage.setItem("is_staff", false)

      }





       //Nuevo método
      if (is_company || is_company=="true"){

      const companyName = data.user_allowed_company[0].company_data;
      const companyId = data.user_allowed_company[0].company;
      const is_active_company = data.user_allowed_company[0].is_active_company;

      

      window.localStorage.setItem("username", companyName) //username es key, y lo otro es el value //guardo el usuario
      window.localStorage.setItem("company_id", companyId) //username es key, y lo otro es el value //guardo el usuario
      window.localStorage.setItem("is_active_company", is_active_company) //different validator
      window.localStorage.setItem("is_company", true)


      }else{
        window.localStorage.setItem("is_company", false)

      }


     

        
      });


    },


  },
  created() {
    this.setUserInfo();
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

#app {
  font-family: Quicksand, sans-serif !important;
}

* {
  margin: 0px;
  padding: 0px;
  outline: 0;
  box-sizing: border-box;
}
body {
  background-color: #141414 !important;
  font-family: Quicksand, sans-serif !important;
}
</style>
