<template>
  <div class="nextReleasesContainer">
    <div class="videoContainer">
      <video
        autoplay
        muted
        playsinline
        loop
        src="../assets/vidFomo.mp4"
        class="video"
      ></video>
    </div>

    <div class="containerReleases">
      <h1>{{ main }}</h1>
      <p>{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideShow",
  props: {
    main: {
      required: false,
    },
    subtitle: {
      required: false,
    },
    type: {
      default: 2,
    },
  },
};
</script>

<style>
.nextReleasesContainer {
  width: 100%;
  height: 500px;
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  padding-left: 20px;
  box-sizing: border-box;
}
.nextReleasesContainer .videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nextReleasesContainer .videoContainer .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nextReleasesContainer .videoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.containerReleases {
  max-width: 600px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  z-index: 1;
  padding: 20px;
}
.containerReleases h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  text-align: left;
}
.containerReleases p {
  font-size: 1.2rem;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 2rem;
  text-align: left;
  color: #fff;

}
@media (max-width: 900px) {
  .nextReleasesContainer {
    height: 50vh;
  }
  .containerReleases h1 {
    font-size: 3rem;
  }
}
@media (max-width: 600px) {
  .nextReleasesContainer {
    height: 40vh;
  }
  .containerReleases h1 {
    font-size: 2.5rem;
  }
  .containerReleases p {
    font-size: 1.25rem;
  }
}
@media (max-width: 400px) {
  .nextReleasesContainer {
    height: 30vh;
  }
  .containerReleases h1 {
    font-size: 2rem;
  }
  .containerReleases p {
    font-size: 1rem;
  }
}
</style>