<template>
  <div style="padding-top: 10px" class="row row-equal">
    <div class="markup-tables flex">
      <va-card>
        <va-card-title>Pagos Pendientes</va-card-title>
        <va-card-content>
          <form @submit.prevent="searchEvents()">
            <div class="row">
              <div class="flex md9">
                <va-input
                  v-model="search"
                  placeholder="Ingrese el Nombre, Apellido o Correo"
                  label="Buscar"
                >
                </va-input>
              </div>
            </div>
          </form>

          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  
                  
      <th scope="col">Nro</th>
      <th scope="col">Fecha Ingreso</th>
      <th scope="col">Nombre Cliente</th>
      <th scope="col">Correo de Compra</th>
      <th scope="col">Evento</th>
      <th scope="col">Monto</th>
      <th scope="col">Estado</th>
      <th scope="col">Acción</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(invoice, index) in events" :key="index">
                

        <td>{{invoice.id}}</td>
      <td>{{formatDate(invoice.date)}}</td>
      <td>{{invoice.user}}</td>
      <td>{{invoice.email}}</td>
      <td>{{invoice.event}}</td>
      <td>{{invoice.total}}</td>
      <td>  <select  class="form-select" :key="invoice.id" @change="addAction(index, $event)">
               <option value=0 disabled selected>Estado</option>
               <option value=3>Aprobado Tarjeta Crédito</option>
               <option value=6>Aprobado Tarjeta Débito</option>
               <option value=4>Aprobado PAYPAL</option>
               <option value=2>Aprobado Transferencia</option>
               <option value=5>Aprobado POS</option>
               <option value=1>Aprobado Efectivo</option>
               <option value=1>Efectivo</option>
               <option value=3>Cortesía</option>
               <option value=7>Rechazar</option>




            </select></td>
      <td><va-button  style="border-color: #e87e35;" @click="createPayment(index)" outline>Confirmar</va-button></td>

                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-end">
            <div class="md4">
              <spring-spinner
                v-if="loading"
                v-show="next"
                :size="60"
                color="blue"
              />

              <button
                v-show="next"
                v-if="!loading"
                @click="getEvents()"
                style="
                  width: 100%;
                  border: transparent;
                  background-color: blue;
                "
                class="btn btn-primary"
              >
                Cargar Más
              </button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import { apiService } from "../../common/api.services.js";
import { SpringSpinner } from 'epic-spinners';
import { apiServiceForm } from "../../common/api.services.js";


export default {
  name: "PendingPaymentList",
  data() {
    return {
      events: [],
      next: null,
      loadingTable: true,
      search: "",
      loading: false,
      promoter_table:[],
      is_staff:localStorage.getItem('is_staff'),
      payment_type:0,
      bank_fee: 0,
      revenue_fee: 0,
      payment_t:0
    };
  },
  components: {
    SpringSpinner,
  },

  props: {
    event: {
      required: true,
    },
  },

  computed: {
    heading() {
      return [
        {
          name: "name",
          title: "Sección",
        },
        {
          name: "name",
          title: "Entradas Emitidas",
        },
        {
          name: "name",
          title: "Entradas Restantes",
        },
      ];
    },
  },

  created() {
    this.getEvents();
  },

  methods: {


    
    addAction(index, event){

      this.payment_type=event.target.value
  

    },

    
    createPayment(index) {



let status_final = 1
this.loading=true
  let  endpoint =  `/api/manual-p/?tran_id=fomoaut`

  this.payment_type


  if (this.payment_type==1){
    this.bank_fee=0
    this.payment_t=1
  }else if(this.payment_type==2){
    this.bank_fee=0
    this.payment_t=2
  }else if(this.payment_type==3){
    this.payment_t=3
  let dataF=(parseFloat(this.events[index].total)*0.015)
   this.bank_fee= (parseFloat(this.events[index].total)*0.0448)+ dataF + (dataF*0.12)
  }else if(this.payment_type==4){
    this.bank_fee=(parseFloat(this.events[index].total)*0.054)+0.30
    this.payment_t=3


  }else if (this.payment_type==5){

    this.bank_fee= (parseFloat(this.events[index].total)*0.0448)
    this.payment_t=5



  }else if(this.payment_type==6){
   let  dataF=(parseFloat(this.events[index].total)*0.005)
   this.bank_fee= (parseFloat(this.events[index].total)*0.0225)+dataF + (dataF*0.12)
   this.payment_t=3
  
  }else if(this.payment_type==7){
   let  dataF=(parseFloat(this.events[index].total)*0.005)
   this.bank_fee= (parseFloat(this.events[index].total)*0.0225)+dataF + (dataF*0.12)
   this.payment_t=1
   status_final=4
  }


  const data = new FormData()
  
  data.append('amount', this.events[index].total)
  data.append('invoice', this.events[index].id)
  data.append('point_of_sale', this.events[index].pos)
  data.append('url', "")
  data.append('url_number', "")
  data.append('comment', "Ingreso Manual")
  data.append('subtotal', this.events[index].subtotal)
  data.append('fee', this.events[index].fee)
  
 
 
  if (this.payment_type==2){
  this.revenue_fee=this.events[index].fee
  }else{
    this.revenue_fee=parseFloat(this.events[index].fee)-parseFloat(this.bank_fee)
    this.revenue_fee=this.revenue_fee.toFixed(2)
  }


     this.bank_fee=parseFloat(this.bank_fee).toFixed(2)


     data.append('bank_fee', this.bank_fee)
     data.append('revenue_fee', this.revenue_fee)

  
  data.append('ticket',"")
  data.append('payment_type',   this.payment_t)
  data.append('status', status_final)
  data.append('is_manual', 1)

  
  apiServiceForm(endpoint, "POST", data)
        .then(data => {
          this.loadingMain=false
          console.log("pago creado")
          console.log(data)
          this.events.splice(index, 1)






        }).catch(error => {

    alert("Error al ingresar el pago. Por favor comunícate con nosotros para revisar el caso.")
    console.log("error pago:")
    console.log(error)
    //alert('Ha ocurrido un ERROR al actualizar el perfil. Inténtelo nuevamente.  SUDEN - CURSOS CON BOLSA DE EMPLEO')
    //window.location.href = "https://www.somossuden.com/inicio/student-registration.html";


  })
  
 //Ver si el usuario tiene cursos adicionales...
// if (this.event.id==16){
//  this.print()

  
// }

 //Cambiar contraseña y estado del usuario a activado...


},




    changeStatus(index){
    this.success = false

    let id = this.events[index].id


    let endpoint =  `/api/payment/${id}/change?type=${this.events[index].status}`
    apiService(endpoint, "PUT")
              .then(data => {
                  if (data.id){
                    this.events.splice(index, 1)
                  }else{
                      //Especificar error
                      this.error=data.err
                  }

    
              })


    
    },





    formatDate(dateString){
              const date = new Date(dateString);
                // Then specify how you want your dates to be formatted

                console.log(date)
            return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
    },



    populateBar() {
      this.generatedBar = {
        labels: ["Tarjeta", "Transferencia", "Efectivo"],
        datasets: [
          {
            label: "$",
            backgroundColor: "blue",
            data: [this.card, this.bank, this.cash],
          },
        ],
      };
    },

    populateData() {
      let labelArr = [];
      let backgroundColorArr = [];
      let dataArr = [];

      for (let index = 0; index < this.eventTickets.length; index++) {
        console.log("paso");

        let lname =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        labelArr.push(lname);

        let maxVal = 0xffffff; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        let color = `#${randColor.toUpperCase()}`;
        backgroundColorArr.push(color);

        let value =
          this.eventTickets[index].initial_quantity -
          this.eventTickets[index].remaining_tickets;
        dataArr.push(value);

        let obj = Object();
        obj.section =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        obj.tickets = value;
        obj.remaining = this.eventTickets[index].remaining_tickets;
        this.total_tickets.push(obj);
      }
      this.loadingTable = false;
      console.log(labelArr);
      console.log(dataArr);
      console.log(backgroundColorArr);

      this.generatedData = {
        labels: labelArr,
        datasets: [
          {
            label: "Tickets x Sección",
            backgroundColor: backgroundColorArr,
            data: dataArr,
          },
        ],
      };
    },

    openEvent(_id) {
      this.$router.push({ name: "EventHostView", params: { slug: _id } });
    },

    searchEvents() {
      this.events = [];
      this.getEvents();
      this.next = null;
    },

    populateDataS(){

      

        let money_count=0
        let sale_count=0


        let array= this.events
        let code_n=array[0].product_code


      





        for (let index = 0; index < array.length; index++) {
          if (array[index].product_code==code_n){
          money_count=parseFloat(money_count)+parseFloat(array[index].amount)
          sale_count++;
         
        }else{
          let payment_obj = Object()
          payment_obj.money=money_count
          payment_obj.code=array[index].product_code
          payment_obj.count=sale_count


          this.promoter_table.push(payment_obj)
          money_count=0
          sale_count=0
          code_n=array[index].product_code
          console.log(code_n)

        }
        }
    },

    getEvents() {
      // make a GET Request to the questions list endpoint and populate the questions array

      //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      let endpoint = "/api/invoices-data/?status=pending";
       

      if (this.next) {
        endpoint = this.next;
        this.loading=true
      }
      this.loadingQuestions = true;
      apiService(endpoint).then((data) => {
        this.events = this.events.concat(data);
        this.showLoading = false;
        this.loading=false
        if (data.next) {
          //si no es null
          this.next = data.next;
        } else {
          //Si no es null
          this.next = null;
        }
       // this.populateDataS()
      });

      
    
    
    },

  },
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
