<template>
  <div style="padding-top: 10px" class="row row-equal">
    
    <div class="markup-tables flex">
      <va-card>
        <va-card-title>Entradas Promocionales</va-card-title>
        <va-card-content>
          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Precio</th>
                  <th>Link</th>
                  <th>Usos Restantes</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="event_t in events" :key="event_t.id">
                  <td>{{ event_t.code }}</td>
                  <td>${{ event_t.price }}</td>
                  <td>
                    <a
                      :href="
                        'https://www.fomoplanes.com/#/event/' +
                        event.slug +
                        '?co=' +
                        event_t.code +
                        '&wanted=true#anchor1'
                      "
                      target="_blank"
                      >Abrir Link</a
                    >
                  </td>
                  <td>{{ event_t.remaining_tickets }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
  import { apiService } from '../../common/api.services.js'

  export default {
    name: 'PromoTable',
    data() {
      return {
        events: [],
        next: null,
        loadingTable: true,
      }
    },
    components: {
    },

    props: {
      event: {
        required: true,
      },
    },

    computed: {
      heading() {
        return [
          {
            name: 'name',
            title: 'Sección',
          },
          {
            name: 'name',
            title: 'Entradas Emitidas',
          },
          {
            name: 'name',
            title: 'Entradas Restantes',
          },
        ]
      },
    },

    created() {
      this.getEvents()
    },

    methods: {
      populateBar() {
        this.generatedBar = {
          labels: ['Tarjeta', 'Transferencia', 'Efectivo'],
          datasets: [
            {
              label: '$',
              backgroundColor: 'blue',
              data: [this.card, this.bank, this.cash],
            },
          ],
        }
      },

      populateData() {
        let labelArr = []
        let backgroundColorArr = []
        let dataArr = []

        for (let index = 0; index < this.eventTickets.length; index++) {
          console.log('paso')

          let lname = this.eventTickets[index].name + '-' + this.eventTickets[index].code
          labelArr.push(lname)

          let maxVal = 0xffffff // 16777215
          let randomNumber = Math.random() * maxVal
          randomNumber = Math.floor(randomNumber)
          randomNumber = randomNumber.toString(16)
          let randColor = randomNumber.padStart(6, 0)
          let color = `#${randColor.toUpperCase()}`
          backgroundColorArr.push(color)

          let value = this.eventTickets[index].initial_quantity - this.eventTickets[index].remaining_tickets
          dataArr.push(value)

          let obj = Object()
          obj.section = this.eventTickets[index].name + '-' + this.eventTickets[index].code
          obj.tickets = value
          obj.remaining = this.eventTickets[index].remaining_tickets
          this.total_tickets.push(obj)
        }
        this.loadingTable = false
        console.log(labelArr)
        console.log(dataArr)
        console.log(backgroundColorArr)

        this.generatedData = {
          labels: labelArr,
          datasets: [
            {
              label: 'Tickets x Sección',
              backgroundColor: backgroundColorArr,
              data: dataArr,
            },
          ],
        }
      },

      openEvent(_id) {
        this.$router.push({ name: 'EventHostView', params: { slug: _id } })
      },

      getEvents() {
        // make a GET Request to the questions list endpoint and populate the questions array

        //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = '/api/event-tickets-report/?event=' + this.event.id + '&notype=1'

        if (this.next) {
          endpoint = this.next
        }
        this.loadingQuestions = true
        apiService(endpoint).then((data) => {
          this.events = this.events.concat(data)
          this.showLoading = false
          if (data.next) {
            //si no es null
            this.next = data.next
          } else {
            //Si no es null
            this.next = null
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }
</style>
