
<template>
<div>

<div>



</div>

<div>

       

 <body>
    <div class="register-photo" style="background-color: #141414;">
        <div class="form-container" style="max-width: 500px;padding: 20px;"><img style="background-position: center;width: 150px;display: block;margin-left: auto;margin-right: auto;width: 40%;" src="../assets/img/oks.png">
            <form class="d-inline" method="post" style="background-color: transparent;max-width: 400px;padding-top: 0px;"><small class="form-text text-muted" style="font-family: Quicksand, sans-serif;margin-top: 0px;">Poweres by Fomo Planes</small>
                <h2 class="text-justify" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;margin-bottom: 0px;"><strong>Kia te ha invitado al evento: KIA OPEN 2023</strong></h2><small class="form-text text-muted">Accede a tu nueva nación con acceso VIP por solo 19,99/mes (cobrado anualmente $239,38 USD)</small><small class="form-text text-muted"></small>
                <div class="form-group"><label class="text-justify" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;margin-bottom: 0px;padding-right: 0PX;padding-left: 0PX;padding-top: 10px;padding-bottom: 10px;font-size: 14px;">Descripción del Evento genérica proporcionada por el organizados. mensaje de 500 palabras aproximadamente<br></label></div>
                <div class="form-group"><button class="btn btn-primary btn-block" type="submit" style="background-color: #ffcb42;font-family: Quicksand, sans-serif;">Siguiente</button></div><a class="already" href="#">www.fomoplanes.com</a>
            </form>
        </div>
    </div>




 </body>

    <footer>
    </footer>


</div>
</div>

</template>

<script>
// @ is an alias to /src
import { apiService } from "../common/api.services.js";



export default {
  name: "InvitationView",

   props: {
    id: {
      required: true
    }
    //log25
    
  },


  data(){
    return{
      search:[],
      searchField:"",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      showLoading: false,
      event: {},
      venue: {},
      category: {},
      city: "",
      eventTickets: [],
      updates: [],
      host: null,
      code: null,
      next: null,
      errCode: false,
      promoCode: "",
      saleMode: false,
      order_fee: 0,
      order_total:0,
      products:0,
      step: 0,
      name: "",
      last_name: "",
      phone: "",
      email: "",
      cc: "",





     

    };
  },
  components: {
  

  },

  created(){
    this.getEvent()
    this.getEventTickets()


  },
  methods:{



      getEvent() {


      // make a GET Request to the questions list endpoint and populate the questions array
      let endpoint = `/api/events/${this.id}/`;
      this.showLoading = true;
      apiService(endpoint).then((data) => {
        this.event = data;
        this.category = data.category
        this.venue = data.venue
        this.city = data.venue.city.name
        this.showLoading = false;
        this.updates = data.updates
        this.event_id = data.id

      });
     
    },

    startSale(){

      this.saleMode=true
      this.step=1

    },
    getEventTickets() {

      
      this.code = this.$route.query.co



// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = "/api/event-tickets/?event=" + this.id
        if (this.code){
            endpoint = endpoint + "&code=" + this.code 
        }

          if (this.host){
            endpoint = endpoint + "&host=" + this.host 
        }
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);
          this.showLoading = false;
        })
      
        
    },
   



newEventTicketsSearch() {


// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      
          let   endpoint = "/api/event-tickets/?event=" + this.id + "&code=" + this.promoCode 
      
        
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);

          if (data.length==0){
            this.errCode=true
          }else{
             this.errCode=false
             this.code=this.promoCode
          }
          this.showLoading = false;
        })
      
        
    },
   
  startUserCreation(products, total, fee){
    this.products=products
    this.order_total=total
    this.order_fee=fee
    this.step++

  },


  startGuestNames(name, last_name, phone, email, cc){
    this.name=name
    this.last_name=last_name
    this.phone=phone
    this.email=email
    this.cc=cc
    this.step++
  },



          


    formatDate(dateString){
        const objectDate  = new Date(dateString);
        
        const year = objectDate.getFullYear();

const month = String(objectDate.getMonth() + 1).padStart(2, '0');

const day = String(objectDate.getDate()).padStart(2, '0');


        let format = day + "." + month + "." + year
              // Then specify how you want your dates to be formatted
         return format;
    },


  },
 

};
</script>

<style>
.register-photo {
  background: #f1f7fc;
  padding: 80px 0;
}

.register-photo .image-holder {
  display: table-cell;
  width: auto;
  background: url(../assets/img/oks.png);
  background-size: cover;
}

.register-photo .form-container {
  display: table;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
}

.register-photo form {
  display: table-cell;
  width: 400px;
  background-color: #ffffff;
  padding: 40px 60px;
  color: #505e6c;
}

@media (max-width:991px) {
  .register-photo form {
    padding: 40px;
  }
}

.register-photo form h2 {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.register-photo form .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}

.register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}

.register-photo form .btn-primary {
  background: #f4476b;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 35px;
  text-shadow: none;
  outline: none !important;
}

.register-photo form .btn-primary:hover, .register-photo form .btn-primary:active {
  background: #eb3b60;
}

.register-photo form .btn-primary:active {
  transform: translateY(1px);
}

.register-photo form .already {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}

    
</style>
