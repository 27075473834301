<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div
    class="modal5"
    :class="{ 'is-active': show }"
    :style="{
      top: modalPosition.y + 'px',
      left: modalPosition.x + 'px',
    }"
  >
    <div class="modal-overlay"></div>
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalPosition: Object,
  },
  data() {
    return {
      hoveredRegionPosition: { x: 0, y: 0 },
    };
  },
};
</script>

<style>
.modal5 {
  position: absolute;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

@screen (max-width: 425px) {
  .modal5 {
    width: 40vw;
  }
}
</style>