export default [
{
    id: "path487-0-0-9-7-7-2-5-6",
    cx: "20974.463",
    cy: "6259.8438",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
     fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6",
    cx: "21280.559",
    cy: "6252.2202",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8",
    cx: "21577.18",
    cy: "6256.686",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7",
    cx: "21867.484",
    cy: "6261.6943",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9",
    cx: "22160.949",
    cy: "6263.5439",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9",
    cx: "22451.254",
    cy: "6258.5366",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0",
    cx: "22744.176",
    cy: "6259.3027",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5",
    cx: "23037.639",
    cy: "6264.3105",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2",
    cx: "23335.025",
    cy: "6265.6187",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6",
    cx: "23629.797",
    cy: "6263.0024",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5",
    cx: "20988.74",
    cy: "6597.0815",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2",
    cx: "21294.834",
    cy: "6589.458",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2",
    cx: "21591.457",
    cy: "6593.9238",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8",
    cx: "21881.762",
    cy: "6598.9321",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4",
    cx: "22175.225",
    cy: "6600.7817",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4",
    cx: "22465.529",
    cy: "6595.7744",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2",
    cx: "22758.451",
    cy: "6596.5405",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1",
    cx: "23051.914",
    cy: "6601.5483",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1",
    cx: "23349.303",
    cy: "6602.8564",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3",
    cx: "23644.074",
    cy: "6600.2402",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5",
    cx: "20995.055",
    cy: "6900.2593",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8",
    cx: "21301.15",
    cy: "6892.6357",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8",
    cx: "21597.773",
    cy: "6897.1016",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5",
    cx: "21888.078",
    cy: "6902.1099",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0",
    cx: "22181.541",
    cy: "6903.9595",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1",
    cx: "22471.846",
    cy: "6898.9521",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0",
    cx: "22764.768",
    cy: "6899.7183",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5",
    cx: "23058.23",
    cy: "6904.7261",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2",
    cx: "23355.619",
    cy: "6906.0342",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7",
    cx: "23650.391",
    cy: "6903.418",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6",
    cx: "17706.646",
    cy: "6248.4507",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24",
    cx: "18012.742",
    cy: "6240.8271",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8",
    cx: "18309.363",
    cy: "6245.293",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3",
    cx: "18599.67",
    cy: "6250.3013",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2",
    cx: "18893.133",
    cy: "6252.1509",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9",
    cx: "19183.438",
    cy: "6247.1436",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3",
    cx: "19476.359",
    cy: "6247.9097",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8",
    cx: "19769.822",
    cy: "6252.9175",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6",
    cx: "20067.211",
    cy: "6254.2256",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9",
    cx: "20361.982",
    cy: "6251.6094",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58",
    cx: "17720.924",
    cy: "6585.6885",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9",
    cx: "18027.02",
    cy: "6578.0649",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0",
    cx: "18323.641",
    cy: "6582.5308",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1",
    cx: "18613.945",
    cy: "6587.5391",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8",
    cx: "18907.408",
    cy: "6589.3887",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14",
    cx: "19197.715",
    cy: "6584.3813",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1",
    cx: "19490.637",
    cy: "6585.1475",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0",
    cx: "19784.1",
    cy: "6590.1553",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6",
    cx: "20081.486",
    cy: "6591.4634",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3",
    cx: "20376.258",
    cy: "6588.8472",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6",
    cx: "17727.24",
    cy: "6888.8662",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2",
    cx: "18033.336",
    cy: "6881.2427",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8",
    cx: "18329.957",
    cy: "6885.7085",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2",
    cx: "18620.262",
    cy: "6890.7168",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9",
    cx: "18913.725",
    cy: "6892.5664",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0",
    cx: "19204.031",
    cy: "6887.5591",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0",
    cx: "19496.953",
    cy: "6888.3252",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8",
    cx: "19790.416",
    cy: "6893.333",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4",
    cx: "20087.803",
    cy: "6894.6411",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4",
    cx: "20382.574",
    cy: "6892.0249",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4",
    cx: "11289.381",
    cy: "6248.4507",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2",
    cx: "11595.477",
    cy: "6240.8271",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2",
    cx: "11892.098",
    cy: "6245.293",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2",
    cx: "12182.403",
    cy: "6250.3013",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9",
    cx: "12475.866",
    cy: "6252.1509",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3",
    cx: "12766.172",
    cy: "6247.1436",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1",
    cx: "13059.094",
    cy: "6247.9097",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1",
    cx: "13352.557",
    cy: "6252.9175",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2",
    cx: "13649.944",
    cy: "6254.2256",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3",
    cx: "13944.716",
    cy: "6251.6094",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71",
    cx: "11303.657",
    cy: "6585.6885",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2",
    cx: "11609.753",
    cy: "6578.0649",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8",
    cx: "11906.374",
    cy: "6582.5308",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9",
    cx: "12196.68",
    cy: "6587.5391",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7",
    cx: "12490.143",
    cy: "6589.3887",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3",
    cx: "12780.448",
    cy: "6584.3813",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7",
    cx: "13073.37",
    cy: "6585.1475",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1",
    cx: "13366.833",
    cy: "6590.1553",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7",
    cx: "13664.221",
    cy: "6591.4634",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8",
    cx: "13958.992",
    cy: "6588.8472",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0",
    cx: "11309.974",
    cy: "6888.8662",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9",
    cx: "11616.068",
    cy: "6881.2427",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5",
    cx: "11912.69",
    cy: "6885.7085",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3",
    cx: "12202.995",
    cy: "6890.7168",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3",
    cx: "12496.459",
    cy: "6892.5664",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7",
    cx: "12786.764",
    cy: "6887.5591",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2",
    cx: "13079.687",
    cy: "6888.3252",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0",
    cx: "13373.149",
    cy: "6893.333",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0",
    cx: "13670.537",
    cy: "6894.6411",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7",
    cx: "13965.309",
    cy: "6892.0249",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3",
    cx: "8131.2769",
    cy: "6235.8184",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2",
    cx: "8437.3721",
    cy: "6228.1948",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6",
    cx: "8733.9941",
    cy: "6232.6606",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1",
    cx: "9024.2988",
    cy: "6237.6689",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2",
    cx: "9317.7627",
    cy: "6239.5186",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2",
    cx: "9608.0674",
    cy: "6234.5112",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6",
    cx: "9900.9893",
    cy: "6235.2773",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8",
    cx: "10194.452",
    cy: "6240.2852",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7",
    cx: "10491.84",
    cy: "6241.5933",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6",
    cx: "10786.611",
    cy: "6238.9771",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0",
    cx: "8145.5532",
    cy: "6573.0562",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3",
    cx: "8451.6484",
    cy: "6565.4326",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1",
    cx: "8748.2705",
    cy: "6569.8984",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1",
    cx: "9038.5752",
    cy: "6574.9067",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3",
    cx: "9332.0391",
    cy: "6576.7563",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5",
    cx: "9622.3438",
    cy: "6571.749",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9",
    cx: "9915.2656",
    cy: "6572.5151",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0",
    cx: "10208.729",
    cy: "6577.5229",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4",
    cx: "10506.116",
    cy: "6578.8311",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2",
    cx: "10800.888",
    cy: "6576.2148",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8",
    cx: "8151.8696",
    cy: "6876.2339",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7",
    cx: "8457.9648",
    cy: "6868.6104",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4",
    cx: "8754.5869",
    cy: "6873.0762",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6",
    cx: "9044.8916",
    cy: "6878.0845",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1",
    cx: "9338.3555",
    cy: "6879.9341",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7",
    cx: "9628.6602",
    cy: "6874.9268",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0",
    cx: "9921.582",
    cy: "6875.6929",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8",
    cx: "10215.045",
    cy: "6880.7007",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2",
    cx: "10512.433",
    cy: "6882.0088",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1",
    cx: "10807.204",
    cy: "6879.3926",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3-2",
    cx: "4998.438",
    cy: "6286.3477",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2-8",
    cx: "5304.5332",
    cy: "6278.7241",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6-0",
    cx: "5601.1548",
    cy: "6283.1899",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1-0",
    cx: "5891.46",
    cy: "6288.1982",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2-4",
    cx: "6184.9233",
    cy: "6290.0479",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2-9",
    cx: "6475.2285",
    cy: "6285.0405",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6-7",
    cx: "6768.1504",
    cy: "6285.8066",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8-1",
    cx: "7061.6133",
    cy: "6290.8145",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7-8",
    cx: "7359.001",
    cy: "6292.1226",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6-5",
    cx: "7653.7725",
    cy: "6289.5063",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0-4",
    cx: "5012.7144",
    cy: "6623.5854",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3-8",
    cx: "5318.8096",
    cy: "6615.9619",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1-2",
    cx: "5615.4312",
    cy: "6620.4277",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1-6",
    cx: "5905.7363",
    cy: "6625.436",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3-2",
    cx: "6199.1997",
    cy: "6627.2856",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5-7",
    cx: "6489.5049",
    cy: "6622.2783",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9-2",
    cx: "6782.4268",
    cy: "6623.0444",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0-7",
    cx: "7075.8896",
    cy: "6628.0522",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4-4",
    cx: "7373.2773",
    cy: "6629.3604",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2-4",
    cx: "7668.0488",
    cy: "6626.7441",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8-5",
    cx: "5019.0303",
    cy: "6926.7632",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7-2",
    cx: "5325.126",
    cy: "6919.1396",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4-1",
    cx: "5621.7476",
    cy: "6923.6055",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6-2",
    cx: "5912.0527",
    cy: "6928.6138",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1-7",
    cx: "6205.5161",
    cy: "6930.4634",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7-9",
    cx: "6495.8213",
    cy: "6925.4561",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0-0",
    cx: "6788.7432",
    cy: "6926.2222",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8-3",
    cx: "7082.2061",
    cy: "6931.23",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2-8",
    cx: "7379.5938",
    cy: "6932.5381",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1-0",
    cx: "7674.3652",
    cy: "6929.9219",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3-2-6",
    cx: "1890.8636",
    cy: "6261.083",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2-8-6",
    cx: "2196.9592",
    cy: "6253.4595",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6-0-0",
    cx: "2493.5808",
    cy: "6257.9253",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1-0-8",
    cx: "2783.886",
    cy: "6262.9336",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2-4-5",
    cx: "3077.3494",
    cy: "6264.7832",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2-9-8",
    cx: "3367.6545",
    cy: "6259.7759",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6-7-3",
    cx: "3660.5764",
    cy: "6260.542",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8-1-3",
    cx: "3954.0393",
    cy: "6265.5498",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7-8-5",
    cx: "4251.4268",
    cy: "6266.8579",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6-5-0",
    cx: "4546.1982",
    cy: "6264.2417",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0-4-7",
    cx: "1905.14",
    cy: "6598.3208",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3-8-7",
    cx: "2211.2356",
    cy: "6590.6973",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1-2-9",
    cx: "2507.8572",
    cy: "6595.1631",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1-6-9",
    cx: "2798.1624",
    cy: "6600.1714",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3-2-8",
    cx: "3091.6257",
    cy: "6602.021",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5-7-3",
    cx: "3381.9309",
    cy: "6597.0137",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9-2-5",
    cx: "3674.8528",
    cy: "6597.7798",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0-7-9",
    cx: "3968.3157",
    cy: "6602.7876",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4-4-5",
    cx: "4265.7031",
    cy: "6604.0957",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2-4-6",
    cx: "4560.4746",
    cy: "6601.4795",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8-5-8",
    cx: "1911.4562",
    cy: "6901.4985",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7-2-6",
    cx: "2217.5518",
    cy: "6893.875",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4-1-7",
    cx: "2514.1733",
    cy: "6898.3408",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6-2-2",
    cx: "2804.4785",
    cy: "6903.3491",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1-7-0",
    cx: "3097.9419",
    cy: "6905.1987",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7-9-5",
    cx: "3388.2471",
    cy: "6900.1914",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0-0-6",
    cx: "3681.1692",
    cy: "6900.9575",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8-3-8",
    cx: "3974.6321",
    cy: "6905.9653",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2-8-6",
    cx: "4272.0195",
    cy: "6907.2734",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1-0-2",
    cx: "4566.791",
    cy: "6904.6572",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-0",
    cx: "14548.544",
    cy: "6248.4507",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-8",
    cx: "14854.64",
    cy: "6240.8271",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-5",
    cx: "15151.261",
    cy: "6245.293",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-4",
    cx: "15441.566",
    cy: "6250.3013",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-3",
    cx: "15735.029",
    cy: "6252.1509",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-2",
    cx: "16025.335",
    cy: "6247.1436",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-2",
    cx: "16318.257",
    cy: "6247.9097",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-3",
    cx: "16611.719",
    cy: "6252.9175",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-9",
    cx: "16909.107",
    cy: "6254.2256",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-2",
    cx: "17203.879",
    cy: "6251.6094",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-7",
    cx: "14562.82",
    cy: "6585.6885",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-9",
    cx: "14868.916",
    cy: "6578.0649",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-1",
    cx: "15165.537",
    cy: "6582.5308",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-4",
    cx: "15455.843",
    cy: "6587.5391",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-0",
    cx: "15749.306",
    cy: "6589.3887",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-9",
    cx: "16039.611",
    cy: "6584.3813",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-9",
    cx: "16332.533",
    cy: "6585.1475",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-3",
    cx: "16625.996",
    cy: "6590.1553",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-5",
    cx: "16923.383",
    cy: "6591.4634",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-7",
    cx: "17218.154",
    cy: "6588.8472",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-9",
    cx: "14569.137",
    cy: "6888.8662",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-8",
    cx: "14875.232",
    cy: "6881.2427",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-3",
    cx: "15171.854",
    cy: "6885.7085",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-9",
    cx: "15462.159",
    cy: "6890.7168",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-9",
    cx: "15755.622",
    cy: "6892.5664",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-6",
    cx: "16045.928",
    cy: "6887.5591",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-6",
    cx: "16338.85",
    cy: "6888.3252",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-2",
    cx: "16632.312",
    cy: "6893.333",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-4",
    cx: "16929.699",
    cy: "6894.6411",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-0",
    cx: "17224.471",
    cy: "6892.0249",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-4",
    cx: "21038.047",
    cy: "17492.514",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-0",
    cx: "21344.143",
    cy: "17484.891",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-1",
    cx: "21640.764",
    cy: "17489.357",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-7",
    cx: "21931.068",
    cy: "17494.365",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-1",
    cx: "22224.533",
    cy: "17496.215",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-95",
    cx: "22514.838",
    cy: "17491.207",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-26",
    cx: "22807.76",
    cy: "17491.973",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-9",
    cx: "23101.223",
    cy: "17496.98",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-0",
    cx: "23398.609",
    cy: "17498.289",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-5",
    cx: "23693.381",
    cy: "17495.674",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-4",
    cx: "21052.324",
    cy: "17829.752",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-0",
    cx: "21358.418",
    cy: "17822.129",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-83",
    cx: "21655.041",
    cy: "17826.594",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-4",
    cx: "21945.346",
    cy: "17831.604",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-3",
    cx: "22238.809",
    cy: "17833.453",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-2",
    cx: "22529.113",
    cy: "17828.445",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-2",
    cx: "22822.035",
    cy: "17829.211",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-1",
    cx: "23115.498",
    cy: "17834.219",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-5",
    cx: "23412.887",
    cy: "17835.527",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-6",
    cx: "23707.658",
    cy: "17832.91",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-9",
    cx: "21058.639",
    cy: "18132.93",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-4",
    cx: "21364.734",
    cy: "18125.307",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-0",
    cx: "21661.357",
    cy: "18129.771",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-6",
    cx: "21951.662",
    cy: "18134.781",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-4",
    cx: "22245.125",
    cy: "18136.631",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-01",
    cx: "22535.43",
    cy: "18131.623",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-2",
    cx: "22828.352",
    cy: "18132.389",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-6",
    cx: "23121.814",
    cy: "18137.396",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-0",
    cx: "23419.203",
    cy: "18138.705",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-6",
    cx: "23713.975",
    cy: "18136.088",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-46",
    cx: "17770.23",
    cy: "17481.121",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-9",
    cx: "18076.326",
    cy: "17473.498",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-57",
    cx: "18372.947",
    cy: "17477.963",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-3",
    cx: "18663.254",
    cy: "17482.973",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-5",
    cx: "18956.717",
    cy: "17484.822",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-32",
    cx: "19247.021",
    cy: "17479.814",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-3",
    cx: "19539.943",
    cy: "17480.58",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-6",
    cx: "19833.406",
    cy: "17485.588",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-98",
    cx: "20130.795",
    cy: "17486.896",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-1",
    cx: "20425.566",
    cy: "17484.279",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-1",
    cx: "17784.508",
    cy: "17818.359",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-7",
    cx: "18090.604",
    cy: "17810.736",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-82",
    cx: "18387.225",
    cy: "17815.201",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-3",
    cx: "18677.529",
    cy: "17820.209",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-9",
    cx: "18970.992",
    cy: "17822.059",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-5",
    cx: "19261.299",
    cy: "17817.053",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-4",
    cx: "19554.221",
    cy: "17817.818",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-0",
    cx: "19847.684",
    cy: "17822.826",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-57",
    cx: "20145.07",
    cy: "17824.135",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-6",
    cx: "20439.842",
    cy: "17821.518",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-1",
    cx: "17790.824",
    cy: "18121.537",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-7",
    cx: "18096.92",
    cy: "18113.914",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-8",
    cx: "18393.541",
    cy: "18118.379",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-6",
    cx: "18683.846",
    cy: "18123.387",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-8",
    cx: "18977.309",
    cy: "18125.236",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-9",
    cx: "19267.615",
    cy: "18120.23",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-3",
    cx: "19560.537",
    cy: "18120.996",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-00",
    cx: "19854",
    cy: "18126.004",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-7",
    cx: "20151.387",
    cy: "18127.312",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-9",
    cx: "20446.158",
    cy: "18124.695",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-1",
    cx: "11352.965",
    cy: "17481.121",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-4",
    cx: "11659.061",
    cy: "17473.498",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-1",
    cx: "11955.683",
    cy: "17477.963",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-8",
    cx: "12245.987",
    cy: "17482.973",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-3",
    cx: "12539.451",
    cy: "17484.822",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-3",
    cx: "12829.756",
    cy: "17479.814",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-8",
    cx: "13122.678",
    cy: "17480.58",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-81",
    cx: "13416.141",
    cy: "17485.588",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-2",
    cx: "13713.528",
    cy: "17486.896",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-7",
    cx: "14008.3",
    cy: "17484.279",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-01",
    cx: "11367.241",
    cy: "17818.359",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-2",
    cx: "11673.337",
    cy: "17810.736",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-0",
    cx: "11969.959",
    cy: "17815.201",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-0",
    cx: "12260.264",
    cy: "17820.209",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-0",
    cx: "12553.728",
    cy: "17822.059",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-2",
    cx: "12844.032",
    cy: "17817.053",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-7",
    cx: "13136.954",
    cy: "17817.818",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-9",
    cx: "13430.417",
    cy: "17822.826",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-44",
    cx: "13727.805",
    cy: "17824.135",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-8",
    cx: "14022.576",
    cy: "17821.518",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-3",
    cx: "11373.558",
    cy: "18121.537",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-4",
    cx: "11679.653",
    cy: "18113.914",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-6",
    cx: "11976.274",
    cy: "18118.379",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-68",
    cx: "12266.58",
    cy: "18123.387",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-3",
    cx: "12560.043",
    cy: "18125.236",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-9",
    cx: "12850.349",
    cy: "18120.23",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-5",
    cx: "13143.271",
    cy: "18120.996",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-2",
    cx: "13436.733",
    cy: "18126.004",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-4",
    cx: "13734.121",
    cy: "18127.312",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-4",
    cx: "14028.893",
    cy: "18124.695",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3-23",
    cx: "8194.8613",
    cy: "17468.49",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2-4",
    cx: "8500.957",
    cy: "17460.865",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6-02",
    cx: "8797.5781",
    cy: "17465.332",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1-05",
    cx: "9087.8838",
    cy: "17470.34",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2-7",
    cx: "9381.3467",
    cy: "17472.189",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2-1",
    cx: "9671.6523",
    cy: "17467.182",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6-74",
    cx: "9964.5742",
    cy: "17467.949",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8-6",
    cx: "10258.037",
    cy: "17472.957",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7-7",
    cx: "10555.425",
    cy: "17474.264",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6-2",
    cx: "10850.196",
    cy: "17471.648",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0-8",
    cx: "8209.1377",
    cy: "17805.727",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3-5",
    cx: "8515.2334",
    cy: "17798.104",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1-3",
    cx: "8811.8545",
    cy: "17802.57",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1-61",
    cx: "9102.1602",
    cy: "17807.578",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3-4",
    cx: "9395.623",
    cy: "17809.428",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5-8",
    cx: "9685.9287",
    cy: "17804.42",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9-4",
    cx: "9978.8506",
    cy: "17805.186",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0-6",
    cx: "10272.313",
    cy: "17810.193",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4-45",
    cx: "10569.701",
    cy: "17811.502",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2-8",
    cx: "10864.473",
    cy: "17808.887",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8-0",
    cx: "8215.4541",
    cy: "18108.904",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7-4",
    cx: "8521.5488",
    cy: "18101.281",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4-0",
    cx: "8818.1709",
    cy: "18105.748",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6-6",
    cx: "9108.4756",
    cy: "18110.756",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1-4",
    cx: "9401.9395",
    cy: "18112.605",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7-6",
    cx: "9692.2441",
    cy: "18107.598",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0-5",
    cx: "9985.167",
    cy: "18108.363",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8-2",
    cx: "10278.63",
    cy: "18113.371",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2-2",
    cx: "10576.018",
    cy: "18114.68",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1-7",
    cx: "10870.789",
    cy: "18112.064",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3-2-9",
    cx: "5062.0225",
    cy: "17519.018",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2-8-8",
    cx: "5368.1177",
    cy: "17511.395",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6-0-1",
    cx: "5664.7393",
    cy: "17515.861",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1-0-0",
    cx: "5955.0444",
    cy: "17520.869",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2-4-2",
    cx: "6248.5078",
    cy: "17522.719",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2-9-1",
    cx: "6538.813",
    cy: "17517.711",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6-7-0",
    cx: "6831.7349",
    cy: "17518.477",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8-1-7",
    cx: "7125.1978",
    cy: "17523.484",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7-8-6",
    cx: "7422.5854",
    cy: "17524.793",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6-5-4",
    cx: "7717.3569",
    cy: "17522.178",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0-4-4",
    cx: "5076.2988",
    cy: "17856.256",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3-8-1",
    cx: "5382.394",
    cy: "17848.633",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1-2-6",
    cx: "5679.0156",
    cy: "17853.098",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1-6-7",
    cx: "5969.3208",
    cy: "17858.107",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3-2-5",
    cx: "6262.7842",
    cy: "17859.957",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5-7-7",
    cx: "6553.0894",
    cy: "17854.949",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9-2-9",
    cx: "6846.0112",
    cy: "17855.715",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0-7-6",
    cx: "7139.4741",
    cy: "17860.723",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4-4-8",
    cx: "7436.8618",
    cy: "17862.031",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2-4-3",
    cx: "7731.6333",
    cy: "17859.414",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8-5-3",
    cx: "5082.6147",
    cy: "18159.434",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7-2-9",
    cx: "5388.7104",
    cy: "18151.811",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4-1-1",
    cx: "5685.332",
    cy: "18156.275",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6-2-7",
    cx: "5975.6372",
    cy: "18161.285",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1-7-4",
    cx: "6269.1006",
    cy: "18163.135",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7-9-0",
    cx: "6559.4058",
    cy: "18158.127",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0-0-0",
    cx: "6852.3276",
    cy: "18158.893",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8-3-7",
    cx: "7145.7905",
    cy: "18163.9",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2-8-0",
    cx: "7443.1782",
    cy: "18165.209",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1-0-5",
    cx: "7737.9497",
    cy: "18162.592",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-4-3-2-6-8",
    cx: "1954.4482",
    cy: "17493.754",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-2-2-8-6-0",
    cx: "2260.5437",
    cy: "17486.131",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-2-6-0-0-0",
    cx: "2557.1653",
    cy: "17490.596",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-2-1-0-8-8",
    cx: "2847.4705",
    cy: "17495.605",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-9-2-4-5-1",
    cx: "3140.9338",
    cy: "17497.455",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-3-2-9-8-4",
    cx: "3431.239",
    cy: "17492.447",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-1-6-7-3-5",
    cx: "3724.1609",
    cy: "17493.213",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-1-8-1-3-3",
    cx: "4017.6238",
    cy: "17498.221",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-2-7-8-5-5",
    cx: "4315.0112",
    cy: "17499.529",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-3-6-5-0-3",
    cx: "4609.7827",
    cy: "17496.912",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-71-0-4-7-7",
    cx: "1968.7246",
    cy: "17830.992",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-2-3-8-7-2",
    cx: "2274.8201",
    cy: "17823.369",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-8-1-2-9-9",
    cx: "2571.4417",
    cy: "17827.834",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-9-1-6-9-3",
    cx: "2861.7468",
    cy: "17832.842",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-7-3-2-8-9",
    cx: "3155.2102",
    cy: "17834.691",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-3-5-7-3-0",
    cx: "3445.5154",
    cy: "17829.686",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-7-9-2-5-2",
    cx: "3738.4373",
    cy: "17830.451",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-1-0-7-9-5",
    cx: "4031.9001",
    cy: "17835.459",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-7-4-4-5-0",
    cx: "4329.2876",
    cy: "17836.768",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-8-2-4-6-7",
    cx: "4624.0591",
    cy: "17834.15",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-0-8-5-8-7",
    cx: "1975.0408",
    cy: "18134.17",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-9-7-2-6-1",
    cx: "2281.1362",
    cy: "18126.547",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-5-4-1-7-9",
    cx: "2577.7578",
    cy: "18131.012",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-3-6-2-2-5",
    cx: "2868.063",
    cy: "18136.02",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-3-1-7-0-6",
    cx: "3161.5264",
    cy: "18137.869",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-7-7-9-5-7",
    cx: "3451.8315",
    cy: "18132.863",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-2-0-0-6-8",
    cx: "3744.7537",
    cy: "18133.629",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-0-8-3-8-5",
    cx: "4038.2166",
    cy: "18138.637",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-0-2-8-6-3",
    cx: "4335.604",
    cy: "18139.945",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-7-1-0-2-1",
    cx: "4630.3755",
    cy: "18137.328",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-6-0-2",
    cx: "14612.128",
    cy: "17481.121",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-24-8-0",
    cx: "14918.224",
    cy: "17473.498",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-8-5-4",
    cx: "15214.845",
    cy: "17477.963",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-3-4-9",
    cx: "15505.15",
    cy: "17482.973",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-2-3-3",
    cx: "15798.613",
    cy: "17484.822",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-9-2-1",
    cx: "16088.919",
    cy: "17479.814",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-3-2-0",
    cx: "16381.841",
    cy: "17480.58",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-8-3-7",
    cx: "16675.303",
    cy: "17485.588",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-6-9-7",
    cx: "16972.691",
    cy: "17486.896",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-9-2-5",
    cx: "17267.463",
    cy: "17484.279",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-58-7-0",
    cx: "14626.404",
    cy: "17818.359",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-9-9-7",
    cx: "14932.5",
    cy: "17810.736",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-0-1-8",
    cx: "15229.121",
    cy: "17815.201",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-1-4-1",
    cx: "15519.427",
    cy: "17820.209",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-8-0-5",
    cx: "15812.89",
    cy: "17822.059",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-14-9-3",
    cx: "16103.195",
    cy: "17817.053",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-1-9-4",
    cx: "16396.117",
    cy: "17817.818",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-0-3-9",
    cx: "16689.58",
    cy: "17822.826",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-6-5-2",
    cx: "16986.967",
    cy: "17824.135",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-3-7-4",
    cx: "17281.738",
    cy: "17821.518",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-9-7-7-2-5-6-5-5-6-9-3",
    cx: "14632.721",
    cy: "18121.537",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-6-2-2-2-2-6-2-8-2-8-6",
    cx: "14938.816",
    cy: "18113.914",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-0-0-3-8-4-8-2-8-8-3-2",
    cx: "15235.438",
    cy: "18118.379",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-6-4-9-6-3-7-8-5-2-9-4",
    cx: "15525.743",
    cy: "18123.387",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-1-1-0-5-3-9-4-0-9-9-6",
    cx: "15819.206",
    cy: "18125.236",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-7-1-8-8-2-9-4-1-0-6-4",
    cx: "16109.512",
    cy: "18120.23",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-2-0-0-5-9-0-2-0-0-6-6",
    cx: "16402.434",
    cy: "18120.996",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-1-4-3-8-0-5-1-5-8-2-0",
    cx: "16695.896",
    cy: "18126.004",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-8-1-0-6-4-2-1-2-4-4-5",
    cx: "16993.283",
    cy: "18127.312",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
},
{
    id: "path487-0-0-5-2-8-0-6-1-9-0-1-3-6-3-6-0-6-3-7-4-0-4",
    cx: "17288.055",
    cy: "18124.695",
    rx: "140.30318",
    ry: "138.1358",
    name: '#_Mytyschi',
   fill: '#ffffe0',
     title: 'Мытищи',
    strokeWidth:  30,
     stroke: '#878787',
   status: 1,
    "text-rendering": "geometricPrecision"
}

]