
<template>
<div>
  <HeaderMain @messageFromHeader="searchEvents"/>

  <div v-if="requestUserStatus==false || requestUserStatus=='false'">

   <DisabledAccount />  


</div>

<div v-else>



       <SlideShow :main="title" :subtitle="subtitle" type="1"/>
       <SearchCourse v-if="!main" :courses="search" />

 <body style="background: rgb(41,7,55);   background: radial-gradient(circle,rgb(10,2,49) 20%, rgb(0,0,0) 100%);">

     
      <p v-if="err" style="color:white; padding-left: 50px; font-family: Raleway, sans-serif;">No se encontraron resultados en su busqueda</p>
     <EventCard v-show="main" />
      <EventCard v-if="!main" :eventsProp="search"/>

   


    

 </body>

    <footer>
        <FooterMain/>
    </footer>


</div>
</div>

</template>

<script>
// @ is an alias to /src
import HeaderMain from "@/components/HeaderMain.vue"
import SlideShow from "@/components/SlideShow.vue"
import SearchCourse from "@/components/SearchCourse.vue";
import DisabledAccount from "@/components/DisabledAccount.vue";
import FooterMain from "@/components/FooterMain.vue";
import EventCard from "@/components/EventCard.vue";









export default {
  name: "HomeMain",

  data(){
    return{
      search:[],
      searchField:"",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      subtitle:"Todos los conciertos, fiestas, eventos, conferencias y experiencias en un solo lugar.",
      title:"BIENVENIDO A UN MUNDO DE PLANES"

    };
  },
  components: {
    HeaderMain,
    SlideShow,
    SearchCourse,
    DisabledAccount,
    FooterMain,
    EventCard,
    
  },


  methods:{
    searchEvents(arg, arg2){
     this.search = arg;
      this.searchField = arg2
     
     if(this.searchField!=""){
      if(this.search.length!=0){
        this.main=false
        this.err=false
      }else{
        this.main=true
        this.err=true;

      }
       }else{
         this.main=true;
         this.err=false;
       }

    },

       setUserData(){

       
      this.requestUserStatus=window.localStorage.getItem("is_active"); //traigo entonces del localstorage el api
      this.requestUserType=window.localStorage.getItem("is_gold"); 


    },

  },
  created() {
    this.setUserData();
  },

};
</script>

<style >

.carousel{
   width: 100%;
  height: 500px;
  position: relative;
  background: #1414147e;
  display: flex;
  align-items: center;
}

  
</style>
