<template>


 <section>

      <div class="container cont">

        <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Paso 1 de 3</small>
                <h2  class="text-justify va-h6" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Selección</strong></h2>


          <div class="table-responsive" style="text-align: center; font-family: 'Raleway', sans-serif;">
    <table class="table" style="width: 100%;  margin: auto;">
        <thead>
            <tr>
                <th style="color: #ffffff; padding: 10px; border-bottom: 2px solid #2a78ea;">Localidad</th>
                <th style="color: #ffffff; padding: 10px; border-bottom: 2px solid #2a78ea;">Precio</th>
                <th style="color: #ffffff; padding: 10px; border-bottom: 2px solid #2a78ea;">Cantidad</th>
                <th style="color: #ffffff; padding: 10px; border-bottom: 2px solid #2a78ea;">Total</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(eventTicket, index) in availableTickets" :key="index" style="color: #c6c5c5;">
                <td class="align-middle" style="padding: 10px; border-bottom: 1px solid #444;">
                    <img v-if="eventTicket.generic_image" :src="eventTicket.generic_image" class="img-fluid img-thumbnail" style="max-width: 80px; border-radius: 8px; margin-bottom: 5px;" alt="">
                    <div>{{ eventTicket.name }}</div>
                    <div v-if="eventTicket.description" style="font-size: 14px; color: #b3b3b3;">{{ eventTicket.description }}</div>
                </td>
                <td class="align-middle" v-if="eventTicket.is_courtesy" style="padding: 10px; border-bottom: 1px solid #444;">Cortesía</td>
                <td class="align-middle" v-else style="padding: 10px; border-bottom: 1px solid #444;">${{ eventTicket.amount }}</td>
                <td class="align-middle" style="padding: 10px; border-bottom: 1px solid #444; white-space: nowrap;">
                    <div v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket) === 1" class="quantity-toggle" style="display: flex; align-items: center; justify-content: center;">
                        <button @click="decrement(eventTicket.quantity, index)" style="background-color: transparent; color: #ffffff; border: 2px solid #ffffff; padding: 5px 10px; border-radius: 4px; cursor: pointer; font-size: 16px; transition: background-color 0.3s, color 0.3s, transform 0.2s;" @mousedown="buttonAnimation($event, 'decrement')" @mouseup="resetAnimation($event)">&minus;</button>
                        <input type="text" :value="eventTicket.quantity" readonly style="width: 40px; height: 36px; text-align: center; color: #ffffff; ; border: none; margin: 0 2px; font-size: 16px; line-height: 36px;">
                        <button @click="increment(eventTicket.quantity, index)" style="background-color: transparent; color: #ffffff; border: 2px solid #ffffff; padding: 5px 10px; border-radius: 4px; cursor: pointer; font-size: 16px; transition: background-color 0.3s, color 0.3s, transform 0.2s;" @mousedown="buttonAnimation($event, 'increment')" @mouseup="resetAnimation($event)">&plus;</button>
                    </div>
                    <div v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket) === 2" style="color: #ffcc00;">Próximo Precio</div>
                    <div v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket) === 0" style="color: red;">Agotado</div>
                </td>
                <td class="align-middle" style="padding: 10px; border-bottom: 1px solid #444;">${{ eventTicket.amount * eventTicket.quantity }}</td>
            </tr>
        </tbody>
    </table>
</div>

          <div style="padding-top: 10px;" v-if="total_quantity!=0">
        <p  class="d-flex justify-content-end" style="color: white; padding-top: 10px;">Total: ${{subtotal.toFixed(2)}}</p>
        <div style="padding-top: 10px;" class="d-flex justify-content-end">
        <button @click="continueSale()" class="btn btn-primary" type="button" style="padding-top: 10px;padding-bottom: 14px;font-family: Quicksand, sans-serif;"><i class="icon-basket"></i>Continuar</button>




      </div>

       
         </div>
      </div> 
        
  </section>

    
</template>


<script>
//import { apiService } from "../../common/api.services.js";

export default {
  
    name: 'SaleQuantity',
    
    props:{
        eventTickets: {
          type: Object
       },
       coordinated_fee:{
         required: true
       },

       fixed_fee:{
         required: true
       },


       coordinated_service:{
         required: true
       }
     },

     

     data(){
     return{
       events:[],
       next:null,
       availableTickets:[],
       order:[],
       total: 0.00,
       subtotal: 0.00,
       fee: 0.00,
       total_quantity: 0,
       today: new Date(),
       hasMap: 0
       
     }
     

   },

    created () {
    this.createTable()
    this.today = new Date();
  },

  
   methods:{

    buttonAnimation(event) {
                event.target.style.transform = 'scale(0.9)';
            },
            resetAnimation(event) {
                event.target.style.transform = 'scale(1)';
            },
      createTable() {


    for (var i = 0; i < this.eventTickets.length; i++){
      console.log(i)

      if (this.eventTickets[i].status==1){
      let uProduct =  {remaining_tickets: this.eventTickets[i].remaining_tickets, host_remaining_tickets: this.eventTickets[i].host_remaining_tickets,   quantity:0, amount: this.eventTickets[i].price,  name: this.eventTickets[i].name, is_courtesy:  this.eventTickets[i].is_courtesy, total: 0, id: this.eventTickets[i].id, sales_date: this.eventTickets[i].sales_date,  expiration_date: this.eventTickets[i].expiration_date, quantity_factor: this.eventTickets[i].quantity, map: this.eventTickets[i].has_map, max_quantity: this.eventTickets[i].max_quantity, description: this.eventTickets[i].description, generic_image: this.eventTickets[i].generic_image};
      this.availableTickets=this.availableTickets.concat(uProduct)
      

    }
        }

      
          
      },

  continueSale(){
    if(this.subtotal!=0){
        //this.fee=(this.subtotal * 0.083333) + 0.5
        let coordinated_fee=parseFloat(this.coordinated_fee)
        let coordinated_service=parseFloat(this.coordinated_service)
        let fixed_fee=parseFloat(this.fixed_fee)

        this.fee=(this.subtotal *coordinated_fee) + (coordinated_service * this.total_quantity) + fixed_fee
      
    }else{
      this.fee=0
    }
      this.total=this.subtotal+this.fee
      
      this.subtotal=this.subtotal.toFixed(2)
      this.fee=this.fee.toFixed(2)
      this.total=this.total.toFixed(2)


      this.$emit('messageFromCart', this.availableTickets, this.subtotal, this.fee, this.total, this.hasMap)
    },


    dateValidation(str, sales, obj) {
    let exp = null;
    let saleDate = null;

    // Asignación inicial de fechas
    if (str) exp = new Date(str);
    if (sales) saleDate = new Date(sales);

    // Validación de fechas desde el objeto
    let datestr = exp; // Fecha de expiración
    let dateexp = saleDate; // Fecha de ventas

    if (obj.venue_expiration_date !== undefined && obj.venue_expiration_date !== null) {
        const venueExpDate = new Date(obj.venue_expiration_date);
        if (!datestr || venueExpDate < datestr) {
            datestr = venueExpDate; // Usa la fecha de expiración más próxima
            console.log("Actualizando fecha de expiración");
        }
    }

    if (obj.venue_sales_date !== undefined && obj.venue_sales_date !== null) {
        const venueSalesDate = new Date(obj.venue_sales_date);
        if (!dateexp || venueSalesDate < dateexp) {
            dateexp = venueSalesDate; // Usa la fecha de venta más próxima
            console.log("Actualizando fecha de ventas");
        }
    }

    exp = datestr;
    saleDate = dateexp;

    let var1 = 1; // Por defecto está disponible

    // 0: Agotado
    // 1: Disponible
    // 2: Próximamente

    // Validación de la fecha de expiración
    if (exp !== undefined && exp !== null) {
        const currentDate = new Date();
        if (currentDate >= exp) {
            var1 = 0; // Expirado
            console.log("Estado: Agotado por expiración");
        }
    }

    // Validación de boletos restantes
    if (obj.remaining_tickets <= 0 || obj.host_remaining_tickets <= 0) {
        var1 = 0; // Agotado
        console.log("Estado: Agotado por boletos restantes");
    }

    // Validación de la fecha de ventas
    if (saleDate !== undefined && saleDate !== null) {
        const currentDate = new Date();
        if (currentDate < saleDate) {
            var1 = 2; // Próximamente disponible
            console.log("Estado: Próximamente disponible");
        }
    }

    console.log("Estado final:", var1);
    return var1; // Devuelve el estado final
},



    


    openEvent(_id) {
      this.$router.push({ name: "event", params: { id: _id } });
    },


    increment (quantity, i) {

      let max=this.availableTickets[i].max_quantity

      if (this.availableTickets[i].remaining_tickets<max){
        max=this.availableTickets[i].remaining_tickets
      }



      if(quantity === max ) {

        this.availableTickets[i].quantity==this.availableTickets[i].max_quantity
      
      
      }else{

      if (this.availableTickets[i].quantity!=this.availableTickets[i].max_quantity){
      this.availableTickets[i].quantity++
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.subtotal=this.subtotal + this.availableTickets[i].amount
      this.subtotal.toFixed(2)
      this.total_quantity=this.total_quantity+(this.availableTickets[i].quantity_factor)
      
      if (this.availableTickets[i].map==true){
        this.hasMap++
      }

    }



           }
    },
    decrement (quantity, i) {
      if(quantity === 0) {
         this.availableTickets[i].quantity=0
           }else{

     
        this.availableTickets[i].quantity--
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.subtotal=this.subtotal - this.availableTickets[i].amount
      this.subtotal.toFixed(2)
      this.total_quantity=this.total_quantity-(this.availableTickets[i].quantity_factor)

      if (this.availableTickets[i].map==true){
        this.hasMap--
      }

       }
    
      
    }

    
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}

.container{
  justify-content:center
}


  
</style>



<style lang="css">
h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
}



.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198,197,197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption, .blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3C4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}




  .quantity-toggle input {
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem;
    background: transparent;
  }
  
  .quantity-toggle button {
    background: transparent;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    
  }


.cont{
   padding-right: 5px;
   padding-left: 5px;
}


@media (max-width: 768px) {
.cont {
    padding-left: 1px;
    padding-right: 1px;
}

.rw{
   padding-right: 10px;
   padding-left: 10px;


}
}

    
</style>

   