<template>

<div class="center1" style="background: rgb(10,2,49);   background: radial-gradient(circle,rgb(10,2,49) 20%, rgb(0,0,0) 100%);">
  <img style="margin-bottom: 10px;" src="https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS%20FOMO%20TODAS%20LAS%20VARIACIONES-02.png" width="350" height="30" class="img d-inline-block align-top" alt="">

  <i class="icc fa fa-qrcode" style="font-size: 300px; color: #999;"></i>
  <div class="square1">
    <div class="scan">
    </div>
  </div>
</div>
</template>

<script>
 
//<Spinner/>
//import Spinner from "../components/Spinner";
export default {
  name: "PreloaderComponent",
  data() {
    return {
      show: true,
      image: 'https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS%20FOMO%20TODAS%20LAS%20VARIACIONES-02.png',
      color: "blue",
      is_student :  window.localStorage.getItem("is_student"),
      is_superuser:  window.localStorage.getItem("is_superuser"), 
      is_allowed: window.localStorage.getItem("is_allowed"),
      is_staff: window.localStorage.getItem("is_staff"),
      is_admin: window.localStorage.getItem("is_admin"),
      is_company: window.localStorage.getItem("is_company"),
      company_id:window.localStorage.getItem("company_id"),
      is_operator: window.localStorage.getItem("is_operator"),
      counter: 0,
      number : 2000
    };
  },
  mounted() {
   // if (this.show) this.showToggle();
  },
  components: {
  },
  methods: {
    showToggle() {

      setTimeout(() => {

        console.log("llego")

        if (window.localStorage.getItem("is_admin")=="true" || this.is_superuser=="true" || window.localStorage.getItem("is_allowed")=="true" || this.is_staff=="true" ||window.localStorage.getItem("is_allowed")==true) {
          this.$router.push("events/");
        }
        
        else if(window.localStorage.getItem("is_operator")=="true" || window.localStorage.getItem("is_operator")==true){
          this.$router.push("operator-events/");
        }else{

            this.showToggle();
             }


      
      }, this.number);

    }
  }
};
</script>

<style>

.center1
{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icc
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square1
{
  width: 350px;
  height: 350px;
  background: transparent;
  border: 5px solid #999;
}

.scan
{
  width: 100%;
  height: 80px;
  background: linear-gradient(cyan, transparent);
  animation: scanning 1.5s linear alternate infinite;
}

@keyframes scanning
{
  0%{transform: translatey(0px);}
  100%{transform: translatey(270px);}
}


</style>

