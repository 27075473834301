<template>
  <div class="about">
<div class="container d-flex justify-content-center">
    <div class="row justify-content-center ">
            <div v-bind:key="course.id"
                    v-for="course in courses.results">
            <img :src="course.image_url" v-on:click="showDetail(course.id)" id="imagemPosterSlide" />
</div>
    </div>
  </div>


        
  </div>
</template>
<script>
export default {
    name: 'SearchCourse',
    props:{
        courses: {
          type: Object
       },
     },
     data(){
       
     return{
       

     }
     

   },
   methods:{
    showDetail(_id) {
      this.$router.push({ name: "Detail", params: { id: _id } });
    },
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}

.container{
  justify-content:center
}


  
</style>