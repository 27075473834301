import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueQRCodeComponent from 'vue-qrcode-component'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { createVuestic } from "vuestic-ui";


import "vuestic-ui/css";

import JsonExcel from "vue-json-excel3";

library.add(faUserSecret)


createApp(App).use(router).use(createVuestic({
    config: {
        colors: {
          variables: {
            background: "black",
          }
        }
    }
})).component('font-awesome-icon', FontAwesomeIcon).component('qr-code', VueQRCodeComponent).component("downloadExcel", JsonExcel).mount('#app')

