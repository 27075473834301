<template>
  <div class="not-found-pages">

            <HeaderAdmin />

            <div class="container" style="padding-top: 40px; padding-bottom: 40px;">



    <h1 style="color: white; padding-bottom: 10px">{{ event.name }}</h1>

    <div class="flex xs12">
      <va-card style="overflow-x: auto">
        <va-card-title>Opciones del Evento</va-card-title>
        <va-card-content>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="value" grow style="width: 100%">
                <template #tabs>
                  <va-tab v-for="title in ['Pagos Pendientes', 'Casos Abiertos', 'Asistentes']" :key="title" :name="title">
                    {{ title }}
                  </va-tab>
                </template>
              </va-tabs>
            </div>
          </div>
        </va-card-content>
      </va-card>
      </div>


  

    <AssistanceList v-show="value == 'Pagos Pendientes'" v-if="!showLoading"  style="padding-top: 20px; padding-bottom: 20px;" />

    <PendingPaymentList v-show="value == 'Casos Abiertos'" v-if="!showLoading"  style="padding-top: 20px; padding-bottom: 20px;" />



        </div>

  </div>
</template>

<script>
  import { apiService } from '../../common/api.services.js'
  import AssistanceList from './AssistanceList.vue'
  import  PendingPaymentList from './PendingPaymentList.vue'


  
  import HeaderAdmin from '../../components/HeaderAdmin.vue'


  export default {
    name: 'EventHostView',
    data() {
      return {
        value: 'Pagos Pendientes',
        events: [],
        event: {},
        next: null,
        eventTickets: [],
        showLoading: false,
        total_tickets: [],
        generatedData: {},
      }
    },

    components: {
      HeaderAdmin,
      AssistanceList,
      PendingPaymentList
    },

    props: {
      slug: {
        required: true,
      },
    },

    created() {
     // this.getEvent()
    },

    methods: {
      getEvent() {
        // make a GET Request to the questions list endpoint and populate the questions array
        let endpoint = `/api/events-reports/${this.slug}/`
        this.showLoading = true
        apiService(endpoint).then((data) => {
          this.event = data
          //this.id=data.id
          //this.category = data.category
          //this.venue = data.venue
          //this.city = data.venue.city.name
          //this.showLoading = false;
          //this.updates = data.updates
          //this.event_id = data.id
          this.getEventTickets()
        })
      },

      getEventTickets() {
        this.code = this.$route.query.co
        this.wanted = this.$route.query.wanted

        // make a GET Request to the questions list endpoint and populate the questions array

        //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = '/api/event-tickets-report/?event=' + this.slug

        if (this.next) {
          endpoint = this.next
        }
        this.loadingQuestions = true

        apiService(endpoint).then((data) => {
          console.log(endpoint)
          this.eventTickets = this.eventTickets.concat(data)
          this.showLoading = false
          this.populateData()
        })
      },

      populateData() {
        let labelArr = []
        let backgroundColorArr = []
        let dataArr = []

        for (let index = 0; index < this.eventTickets.length; index++) {
          console.log('paso')

          let lname = this.eventTickets[index].name + '-' + this.eventTickets[index].code
          labelArr.push(lname)

          let maxVal = 0xffffff // 16777215
          let randomNumber = Math.random() * maxVal
          randomNumber = Math.floor(randomNumber)
          randomNumber = randomNumber.toString(16)
          let randColor = randomNumber.padStart(6, 0)
          let color = `#${randColor.toUpperCase()}`
          backgroundColorArr.push(color)

          let value = this.eventTickets[index].initial_quantity - this.eventTickets[index].host_remaining_tickets
          dataArr.push(value)

          let obj = Object()
          obj.id = this.eventTickets[index].id
          obj.sect = this.eventTickets[index].name + '-' + this.eventTickets[index].code
          obj.tkt = value
          obj.rem = this.eventTickets[index].remaining_tickets
          this.total_tickets.push(obj)
        }
        this.loadingTable = false

        this.generatedData = {
          labels: labelArr,
          datasets: [
            {
              label: 'Tickets x Sección',
              backgroundColor: backgroundColorArr,
              data: dataArr,
            },
          ],
        }
      },

      openEvent(id) {
        this.$router.push({ name: 'event-host', params: { id: id } })
      },

      getEvents() {
        // make a GET Request to the questions list endpoint and populate the questions array

        //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = '/api/host/events/'

        if (this.next) {
          endpoint = this.next
        }
        this.loadingQuestions = true
        apiService(endpoint).then((data) => {
          this.events = this.events.concat(data)
          this.showLoading = false
          if (data.next) {
            //si no es null
            this.next = data.next
          } else {
            //Si no es null
            this.next = null
          }
        })
      },
    },
  }
</script>
