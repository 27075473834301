<template>


  <div class="header">
    <nav class="navbar navbar-expand-lg">
      
        <router-link class="navbar-brand2 log-web" href="#"
            :to="{ name: 'events'}"
            >


              <img src="https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS%20FOMO%20TODAS%20LAS%20VARIACIONES-02.png" width="290" height="25" class="img d-inline-block align-top" alt="">
    


       </router-link>


       <router-link class="navbar-brand2 log-mob" href="#"
            :to="{ name: 'events'}"
            >


              <img src="https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS FOMO TODAS LAS VARIACIONES-04.png" width="50" height="60" class="img d-inline-block align-top" alt="">
    


       </router-link>


 
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="true"
        aria-label="Toggle navigation"
        width="30" height="30"
      >
    <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
      </button>

      <div class="collapse navbar-collapse center" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto text-md-center text-left">
          <li class="nav-item active">
            <router-link class="nav-link" :to="{ name: 'events'}">
              Mis Eventos
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item">
            
              <router-link class="nav-link" :to="{ name: 'finances'}">
              Finanzas
              <span class="sr-only"></span>
            </router-link>          
          
          </li>

          <li class="nav-item">
            
              <router-link class="nav-link" :to="{ name: 'promo'}">
              Promociones
              <span class="sr-only"></span>
            </router-link>          
          
          </li>


           <li class="nav-item">
            
              <router-link class="nav-link" :to="{ name: 'LogOut'}">
              Salir
              <span class="sr-only"></span>
            </router-link>          
          
          </li>

          
        </ul>
        <div class="search">
          <div class="container h-100">
            <div class="d-flex justify-content-center h-100">                
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { apiService } from "../common/api.services.js";

export default {
  name: "HeaderMain",
  data(){
    return {
      userInput:"",
      courses:[]
    };
  },

  methods:{
    change(){
       let endpoint = "api/courses/?search=" + this.userInput
       apiService(endpoint).then((data) => {
        this.courses = data;

      });

      this.$emit('messageFromHeader', this.courses, this.userInput)
    },

    searchCourses(){
     

      
    },

    openWss(){
      window.location.href = "/account"

    }


  }
}
</script>

<style>
.header {
  color: aliceblue;
  background-color: transparent !important;
}

.header .navbar-toggler {
  height: 40px;
}


.header .navbar {
  background-color: transparent !important;
  color: white;
}

.header .navbar-brand {
  color: white;
}



.header .navbar-brand {
  color: white;
}

.header .nav-link a li {
  color: white;
  font-family: Quicksand sans-serif !important; 
  font-weight: 600;
}

.header li a,
ul.nav li a:visited {
  color: white !important;
}

.header li a:hover,
ul.nav li a:active {
  color: white !important;
}

.header li.active a {
  color: white !important;
}

.header .search {
  margin-right: 0;
}
.header .searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #353b48;
  border-radius: 30px;
  padding: 10px;
  margin-right: 0;
}

.header .search_input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}

.header .searchbar:hover > .search_input {
  padding: 0 10px;
  width: 250px;
  caret-color: red;
  transition: width 0.4s linear;
}

.header .searchbar:hover > .search_icon {
  background: white;
  color: #FFCB43;
}

.header .search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-decoration: none;
}

.header .home-link{
   color: white;
   outline: none;
}
.header .home-link:active, .header  .home-link:focus, .header  .home-link:hover{
   color: white;
   outline: none;
}


.log-mob{
  display: none;
  
}



@media screen and (max-width: 768px) {

  
.header .navbar-brand {
  display: none;
}

.log-web{
  display: none;
}


.log-mob{
  display: block;
  
}


}




</style>