<template>

<button class="js-payment-checkout">Pay with Card</button>


</template>




<script>

export default {
  name: 'VueNuvei',


  props:{

reference: {
    type: String
 }

},


 
  async mounted() {

    this.$nextTick(() => {
      const that=this
    let paymentCheckout = new window.PaymentCheckout.modal({
    env_mode: "prod", // `prod`, `stg`, `local` to change environment. Default is `stg`
    onOpen: function () {
      console.log("modal open");
    },
    onClose() {
      console.log("modal closed");
      console.log(this)
      that.$emit('returnMessageFromNuvei')
    },
    onResponse: function (response) { // The callback to invoke when the Checkout process is completed

      console.log(response)

      try {
        console.log(response.transaction.status)
        if (response.transaction.status=="success" && response.transaction.status_detail==3){
      that.$emit('messageFromNuvei', true, response.transaction.id, response.transaction.authorization_code)
        }else{
          that.$emit('returnMessageFromNuvei')
          alert("El pago no ha sido procesado. Inténtelo nuevamente")
        }
      } catch (error) {
        alert("Ha existido un error y el pago no ha sido procesado.")
        that.$emit('returnMessageFromNuvei')
      }
      //emit
      /*
        In Case of an error, this will be the response.
        response = {
          "error": {
            "type": "Server Error",
            "help": "Try Again Later",
            "description": "Sorry, there was a problem loading Checkout."
          }
        }
        When the User completes all the Flow in the Checkout, this will be the response.
        response = {
          "transaction":{
              "status": "success", // success or failure
              "id": "CB-81011", // transaction_id
              "status_detail": 3 // for the status detail please refer to: https://paymentez.github.io/api-doc/#status-details
          }
        }
      */
      console.log("modal response");
    }
  });

  
    paymentCheckout.open({
      reference: this.reference // reference received for Payment Gateway
    });
  

  window.addEventListener('popstate', function () {
    paymentCheckout.close();
    
  });
})
  },


  data() {
    return {
      paid: false,
    };
  },

  

 
};
</script>



<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>