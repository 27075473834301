<template>
  <div style="padding-top: 10px" class="row row-equal">
    <div class="markup-tables flex">
      <va-card>
        <va-card-title>Depósitos a Cuenta</va-card-title>
        <va-card-content>
          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Banco</th>
                  <th>Cuenta</th>
                  <th>Monto</th>
                  <th>Comentario</th>
                  <th>Archivo</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(event_t, index) in deposits" :key="index">
                  <td>{{ event_t.date }}</td>
                  <td>{{ event_t.account.bank_name}}</td>
                  <td>{{ event_t.account.number }}</td>
                  <td>{{ event_t.amount }}</td>
                  <td>{{ event_t.comment }}</td>
                  <td><a :href="event_t.file">Descargar</a></td>

                  
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-end">
            <div class="md4">
              <spring-spinner
                v-if="loading"
                v-show="next"
                :size="60"
                color="blue"
              />

              <button
                v-show="next"
                v-if="!loading"
                @click="getDeposits()"
                style="
                  width: 100%;
                  border: transparent;
                  background-color: blue;
                "
                class="btn btn-primary"
              >
                Cargar Más
              </button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>



    
  </div>
</template>

<script>
import { apiService } from "../../common/api.services.js";
import { SpringSpinner } from 'epic-spinners';


export default {
  name: "EventFinance",
  data() {
    return {
      deposits: [],
      next: null,
      loadingTable: true,
      search: "",
      loading: false,
      promoter_table:[],
      is_staff:localStorage.getItem('is_staff'),
      pos: 0,
      is_generating: false
    };
  },
  components: {
    SpringSpinner,
  },

  props: {
    event: {
      required: true,
    },
  },

  computed: {
    heading() {
      return [
        {
          name: "name",
          title: "Sección",
        },
        {
          name: "name",
          title: "Entradas Emitidas",
        },
        {
          name: "name",
          title: "Entradas Restantes",
        },
      ];
    },
  },

  created() {
    this.getDeposits();
  },

  methods: {


    formatDate(dateString){
              const date = new Date(dateString);
                // Then specify how you want your dates to be formatted

                console.log(date)
            return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
    },



    populateBar() {
      this.generatedBar = {
        labels: ["Tarjeta", "Transferencia", "Efectivo"],
        datasets: [
          {
            label: "$",
            backgroundColor: "blue",
            data: [this.card, this.bank, this.cash],
          },
        ],
      };
    },

    populateData() {
      let labelArr = [];
      let backgroundColorArr = [];
      let dataArr = [];

      for (let index = 0; index < this.eventTickets.length; index++) {
        console.log("paso");

        let lname =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        labelArr.push(lname);

        let maxVal = 0xffffff; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        let color = `#${randColor.toUpperCase()}`;
        backgroundColorArr.push(color);

        let value =
          this.eventTickets[index].initial_quantity -
          this.eventTickets[index].remaining_tickets;
        dataArr.push(value);

        let obj = Object();
        obj.section =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        obj.tickets = value;
        obj.remaining = this.eventTickets[index].remaining_tickets;
        this.total_tickets.push(obj);
      }
      this.loadingTable = false;
      console.log(labelArr);
      console.log(dataArr);
      console.log(backgroundColorArr);

      this.generatedData = {
        labels: labelArr,
        datasets: [
          {
            label: "Tickets x Sección",
            backgroundColor: backgroundColorArr,
            data: dataArr,
          },
        ],
      };
    },

    openEvent(_id) {
      this.$router.push({ name: "EventHostView", params: { slug: _id } });
    },

    searchEvents() {
      this.events = [];
      this.getDeposits();
      this.next = null;
    },

    populateDataS(){

      

        let money_count=0
        let sale_count=0


        let array= this.events
        let code_n=array[0].product_code


      





        for (let index = 0; index < array.length; index++) {
          if (array[index].product_code==code_n){
          money_count=parseFloat(money_count)+parseFloat(array[index].amount)
          sale_count++;
         
        }else{
          let payment_obj = Object()
          payment_obj.money=money_count
          payment_obj.code=array[index].product_code
          payment_obj.count=sale_count


          this.promoter_table.push(payment_obj)
          money_count=0
          sale_count=0
          code_n=array[index].product_code
          console.log(code_n)

        }
        }
    },

    generatePdf(p){
      this.pos=p
      this.showPdf=true
      this.is_generating=true

    },

    afterPdf(){
      this.pos=0
      this.showPdf=false
      this.is_generating=false

    },

    getDeposits() {
      // make a GET Request to the questions list endpoint and populate the questions array

      //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      let endpoint = "/api/deposits/?event=" + this.event.id;


      if (this.search != "") {
        endpoint =
          "/api/invoice-items/?&search=" +
          this.search +
          "&event=" +
          this.event.id;
      }

      if (this.next) {
        endpoint = this.next;
        this.loading=true
      }
      this.loadingQuestions = true;
      apiService(endpoint).then((data) => {
        this.deposits = this.deposits.concat(data);
        this.showLoading = false;
        this.loading=false
        if (data.next) {
          //si no es null
          this.next = data.next;
        } else {
          //Si no es null
          this.next = null;
        }
       // this.populateDataS()
      });

      
    
    
    },

  },
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
