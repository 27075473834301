<template>
  <section>


    <div class="container c-1">
      <div v-if="!eventsProp" class="row">
        <div v-for="(event, index) in events" :key="index" class="col-md-4">
          <div @click="openEvent(event.slug, event.access)" class="blog-card blog-card-blog">
            <div class="blog-card-image">
              <a><img class="img-fluid img" :src="event.banner_url" /></a>
              <div class="ripple-cont"></div>
            </div>
            <div class="blog-table">
              <h6
                class="blog-category blog-text-success"
                style="color: rgb(198, 197, 197)"
              >
                <i :class="event.category.icon"></i>&nbsp;{{
                  event.category.name
                }}
              </h6>
              <h4
                class="blog-card-caption"
                style="
                  margin: -31px 0px 8px;
                  margin-bottom: -6px;
                  margin-top: -26px;
                "
              >
                <a
                  style="
                    color: rgb(254, 254, 254);
                    font-family: Raleway, sans-serif;
                  "
                  ><br />{{ event.name }}<br
                /></a>
              </h4>
              <div class="ftr">
                <div class="author">
                  <a href="https://github.com/DesignofCp" target="_blank"
                    ><span style="color: rgb(42, 120, 234)">{{
                      event.venue.city.name
                    }}</span></a
                  >
                </div>
                
                <div v-if="event.access==4" class="stats">
                  <i class="far fa-clock" style="color: rgb(254, 254, 254)"></i
                  ><span style="color: rgb(254, 254, 254)"
                    >&nbsp;Ver Fechas</span
                  >
                </div>


                <div v-if="event.access!=4" class="stats">
                  <i class="far fa-clock" style="color: rgb(254, 254, 254)"></i
                  ><span style="color: rgb(254, 254, 254)"
                    >&nbsp;{{ formatDate(event.start_date) }}</span
                  >
                </div>



              </div>
            </div>
          </div>
        </div>


      </div>

       <div v-if="eventsProp" class="row">
        
        
        <div v-for="(event, index) in eventsProp" :key="index" class="col-md-4">
          <div @click="openEvent(event.slug, event.access)" class="blog-card blog-card-blog">
            <div class="blog-card-image">
              <a><img class="img-fluid img" :src="event.banner_url" /></a>
              <div class="ripple-cont"></div>
            </div>
            <div class="blog-table">
              <h6
                class="blog-category blog-text-success"
                style="color: rgb(198, 197, 197)"
              >
                <i :class="event.category.icon"></i>&nbsp;{{
                  event.category.name
                }}
              </h6>
              <h4
                class="blog-card-caption"
                style="
                  margin: -31px 0px 8px;
                  margin-bottom: -6px;
                  margin-top: -26px;
                "
              >
                <a
                  style="
                    color: rgb(254, 254, 254);
                    font-family: Raleway, sans-serif;
                  "
                  ><br />{{ event.name }}<br
                /></a>
              </h4>
              <div class="ftr">
                <div class="author">
                  <a href="https://github.com/DesignofCp" target="_blank"
                    ><span style="color: rgb(42, 120, 234)">{{
                      event.venue.city.name
                    }}</span></a
                  >
                </div>

                <div v-if="event.access==4" class="stats">
                  <i class="far fa-clock" style="color: rgb(254, 254, 254)"></i
                  ><span style="color: rgb(254, 254, 254)"
                    >&nbsp;Ver Fechas</span
                  >
                </div>


                <div v-if="event.access!=4" class="stats">
                  <i class="far fa-clock" style="color: rgb(254, 254, 254)"></i
                  ><span style="color: rgb(254, 254, 254)"
                    >&nbsp;{{ formatDate(event.start_date) }}</span
                  >
                </div>


                
              </div>
            </div>
          </div>


          


        </div>
      </div>


      <div v-if="next">
        <div v-if="!loadingMain" style="padding-top: 10px; padding-bottom: 20px;" class="d-flex justify-content-end">
        <button @click="moreEvents()" class="btn btn-primary" type="button" style="padding-top: 10px;padding-bottom: 14px;font-family: Quicksand, sans-serif;"><i class="icon-basket"></i>Cargar Más Eventos</button>    
      </div>


      <div v-if="loadingMain" style="padding-top: 10px; padding-bottom: 20px;" class="d-flex justify-content-end">

<scaling-squares-spinner
                :size="60"
                color="blue"
              />

      </div>

      </div>

      
    </div>
  </section>
</template>

<script>
import { apiService } from "../common/api.services.js";
import { ScalingSquaresSpinner   } from 'epic-spinners';


export default {
  name: "EventCard",

  components: {ScalingSquaresSpinner},
  props: {
    eventsProp: {
      type: Object,
    },

    host: {
      required: false,
    },
  },
  data() {
    return {
      events: [],
      next: null,
      loadingMain: false,
    };
  },

  created() {
    this.getEvents();
  },

  methods: {
    formatDate(dateString) {
      const objectDate = new Date(dateString);

      const year = objectDate.getFullYear();

      const month = String(objectDate.getMonth() + 1).padStart(2, "0");

      const day = String(objectDate.getDate()).padStart(2, "0");

      let format = day + "." + month + "." + year;
      // Then specify how you want your dates to be formatted
      return format;
    },

    openEvent(slug, type) {
      

      if (type==4){
      this.$router.push({ name: "host", params: { host: slug } });
    }else{
      this.$router.push({ name: "event", params: { slug: slug } });

    }

    },

    moreEvents(){
      this.loadingMain = true;

      this.getEvents()

    },

    getEvents() {
      // make a GET Request to the questions list endpoint and populate the questions array

      //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      let endpoint = "/api/events/?type=1";

      if (this.host!=null|| this.host!=undefined){
        endpoint = "/api/events/?type=3&host="+ this.host
      }

      console.log(endpoint)



      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;
      apiService(endpoint).then((data) => {
        this.loadingMain = false;
        this.events = this.events.concat(data.results);
        this.showLoading = false;
        if (data.next) {
          //si no es null
          this.next = data.next;
        } else {
          //Si no es null
          this.next = null;
        }
      });
    },
  },
};
</script>

<style>

.c-1{
  padding-top:40px;

}

@media (max-width: 768px) {
.c-1{
  padding-top:0px;
  margin-top: -10px;

}

}
#imagemPosterSlide {
  padding: 10px;
}

.container {
  justify-content: center;
}
</style>



<style lang="css">
h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
  cursor: pointer;

}

.blog-card .blog-card-image {
  height: 60%;
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.blog-card .blog-card-image img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  pointer-events: none;
}

.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198, 197, 197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption,
.blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3c4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}
</style>

   