<template>


<footer class="footer">
...
  
    <div>

        <div class="footer-dark" style="background-color:#141414;">
        <div style="text-align:center;">
            <h2 class="divider-style" style="margin-top:0px;"><span style="background-color: rgb(20,20,20);font-family: Quicksand, sans-serif;padding-right: 0px;padding-left: 0px;"><a href="#"></a>Fomo Planes</span></h2>
        </div>
        <footer>
            <div class="container">
                <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 item text" style="margin-bottom:0px;">
                        <h3>Fear of Missing Out</h3>
                        <p>Todos los conciertos, eventos, fiestas, conferencias en un solo lugar</p>
                    </div>
                    <div class="col-sm-6 col-md-3 item">
                        <h3>Sobre</h3>
                        <ul>
                            <li><a href="https://api.whatsapp.com/send?phone=593988959409&text=Hola%20FOMOPLANES%20mi%20nombre%20es%3A%20">Soporte</a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=593988959409&text=Hola%20FOMOPLANES%20mi%20nombre%20es%3A%20">Contactos</a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=593988959409&text=Hola%20FOMOPLANES%20mi%20nombre%20es%3A%20">Inversores</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-3 item">
                        <h3>Links de Interes</h3>
                        <ul>


                            <li><a @click="openTerms()">Términos y Condiciones</a></li>
                            <li><a @click="openTerms()" href="#">Política de Privacidad</a></li>
                            <li><a @click="openTerms()" href="#">Avisos Legales</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col item social" style="margin-top:15px;"><a href="https://www.instagram.com/fomoplanes/"><i class="fab fa-instagram"></i></a><a href="https://www.facebook.com/fomoplanes/"><i class="fab fa-facebook-f"></i></a><a href="https://api.whatsapp.com/send?phone=593988959409&text=Hola%20FOMOPLANES%20mi%20nombre%20es%3A%20"><i class="fab fa-whatsapp"></i></a><a href="https://www.tiktok.com/@fomoplanes"><i class="fab fa-tiktok"></i></a><a href="mailto:fomoplanes@sudenec.com"><i class="fa fa-envelope"></i></a></div>
                <div style="text-align:center;margin-top:-40px;">
                    <h2 class="divider-style"></h2>
                </div>
                <p class="copyright">Fomo Planes © 2024 | Gingo Enterprises Group</p>
            </div>
        </footer>
            </div>

    </div>

      </footer>
</template>


<script>
export default {
     name: "FooterMain",

     methods: {

      openTerms(){
        this.$router.push({ name: "terms" });

        

      }

     }
    
}
</script>



<style>
#footer{
 position:fixed;
 bottom:0;
}

.divider-style {
  margin-top: 70px;
  position: relative;
  margin-right: 40px;
  margin-left: 40px;
}

.divider-style span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.divider-style:before {
  content: "";
  display: block;
  border-top: solid 1px black;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #141414;
  background:  #141414;
  
  
  
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255,255,255,0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

.navigation-clean-button {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean-button .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean-button .navbar-brand:hover {
  color: #222;
}

.navigation-clean-button .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean-button .navbar-toggler:hover, .navigation-clean-button .navbar-toggler:focus {
  background: none;
}

.navigation-clean-button .navbar-toggler {
  color: #888;
}

.navigation-clean-button .navbar-collapse, .navigation-clean-button .form-inline {
  border-top-color: #ddd;
}

.navigation-clean-button .navbar-nav a.active, .navigation-clean-button .navbar-nav > .show > a {
  background: none;
  box-shadow: none;
}

.navigation-clean-button.navbar-light .navbar-nav a.active, .navigation-clean-button.navbar-light .navbar-nav a.active:focus, .navigation-clean-button.navbar-light .navbar-nav a.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean-button.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link:focus, .navigation-clean-button.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean-button {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean-button .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean-button .navbar-brand:hover {
  color: #222;
}

.navigation-clean-button .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean-button .navbar-toggler:hover, .navigation-clean-button .navbar-toggler:focus {
  background: none;
}

.navigation-clean-button .navbar-toggler {
  color: #888;
}

.navigation-clean-button .navbar-collapse, .navigation-clean-button .form-inline {
  border-top-color: #ddd;
}

.navigation-clean-button .navbar-nav a.active, .navigation-clean-button .navbar-nav > .show > a {
  background: none;
  box-shadow: none;
}

.navigation-clean-button.navbar-light .navbar-nav a.active, .navigation-clean-button.navbar-light .navbar-nav a.active:focus, .navigation-clean-button.navbar-light .navbar-nav a.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean-button.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean-button.navbar-light .navbar-nav .nav-link:focus, .navigation-clean-button.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean-button .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus, .navigation-clean-button .dropdown-menu .dropdown-item {
  line-height: 2;
  font-size: 14px;
  color: #37434d;
}

.navigation-clean-button .dropdown-menu .dropdown-item:focus, .navigation-clean-button .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

.navigation-clean-button .actions .login {
  margin-right: 1rem;
  text-decoration: none;
  color: #465765;
}

.navigation-clean-button .navbar-text .action-button, .navigation-clean-button .navbar-text .action-button:active, .navigation-clean-button .navbar-text .action-button:hover {
  background: #56c6c6;
  border-radius: 20px;
  font-size: inherit;
  color: #fff;
  box-shadow: none;
  border: none;
  text-shadow: none;
  padding: .5rem 1rem;
  transition: background-color 0.25s;
  font-size: inherit;
}

.navigation-clean-button .navbar-text .action-button:hover {
  background: #66d7d7;
}
.navigation-clean-search {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean-search {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean-search .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean-search .navbar-brand:hover {
  color: #222;
}

.navigation-clean-search .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean-search .navbar-toggler:hover, .navigation-clean-search .navbar-toggler:focus {
  background: none;
}

.navigation-clean-search .navbar-toggler {
  color: #888;
}

.navigation-clean-search .navbar-collapse, .navigation-clean-search .form-inline {
  border-top-color: #ddd;
}

.navigation-clean-search .navbar-nav a.active, .navigation-clean-search .navbar-nav > .show > a {
  background: none;
  box-shadow: none;
}

.navigation-clean-search.navbar-light .navbar-nav a.active, .navigation-clean-search.navbar-light .navbar-nav a.active:focus, .navigation-clean-search.navbar-light .navbar-nav a.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean-search.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean-search.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean-search.navbar-light .navbar-nav .nav-link:focus, .navigation-clean-search.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean-search .action-button, .navigation-clean-button .action-button:active {
  background: #56c6c6;
  border-radius: 20px;
  font-size: inherit;
  color: #fff !important;
  box-shadow: none;
  border: none;
  text-shadow: none;
  padding: 8px 20px;
  transition: background-color 0.25s;
}

.navigation-clean-search .action-button:hover, .navigation-clean-search .action-button:focus {
  background: #66d7d7;
}

.navigation-clean-search .form-inline label {
  color: #ccc;
}

.navigation-clean-search .form-inline .search-field {
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: #ccc;
  box-shadow: none;
  color: inherit;
  transition: border-bottom-color 0.3s;
}

@media (max-width:767px) {
  .navigation-clean-search .form-inline .search-field {
    display: inline-block;
    width: 80%;
  }
}

.navigation-clean-search .form-inline .search-field:focus {
  border-bottom: 1px solid #ccc;
}

.divider-style {
  margin-top: 70px;
  position: relative;
  margin-right: 40px;
  margin-left: 40px;
}

.divider-style span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.divider-style:before {
  content: "";
  display: block;
  border-top: solid 1px black;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #282d32;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255,255,255,0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

#footerpad {
  padding-top: 70px;
  padding-bottom: 20px;
  background-color: #000000;
}

a:focus, a:hover {
  color: #0085A1;
}

    
</style>
