<template>

        <div v-if="!paid" id="paypal-button-container"></div>    
    
    </template>

<script>
import { loadScript } from '@paypal/paypal-js';
export default {
  name: 'VueEx',
  beforeCreate: function() {
    loadScript({ 'client-id': CLIENT_ID }).then((paypal) => {
      paypal
        .Buttons({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
        })
        .render('#paypal-button-container');
    });
  },
  data() {
    return {
      paid: false,
    };
  },
  props: {
    cartTotal: {
      type: Number,
      default: 0.01,
    },
  },
  methods: {
    createOrder: function(data, actions) {
      console.log('Creating order...');
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: this.cartTotal,
            },
          },
        ],

        application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
      });
    },
    onApprove: function(data, actions) {
      console.log('Order approved...');
      console.log(data)
      let tran_id=data.orderID
      console.log(tran_id)
      return actions.order.capture().then(() => {
        this.paid = true;
       
        this.$emit('messageFromPaypal', tran_id)
        //emit
        console.log('Order complete');
      });
    },
  },
};
const CLIENT_ID = 'ASBsW-vGnNIzt-UnyZh3_FHirYsqa3alWmdHPpjEYp-HaCH1lFaTWvVShCOZuiRpu4kwvb-GDItEm4RM';
</script>



<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>