<template>
  <!-- Sección 2: Precios y Promotores -->
  <div class="block-content" style="background: transparent; border: 2px solid rgba(204,204,204,0.36); border-radius: 30px; padding: 20px;">
    <div class="product-info container-fluid">
      <div class="row">
        <form action="" method="post">
          <h5 class="va-h5" style="margin-bottom: 15px;color: rgb(255,255,255);font-family: Quicksand, sans-serif;">2. Etapas</h5>

          <div class="row" v-for="(stage, index) in stages" :key="index" style="margin-bottom: 15px;">
            <div class="col-4 col-md-3">
              <input type="text" v-model="stage.name" placeholder="Nombre de Etapa" class="form-control" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" />
            </div>
            <div class="col-3 col-md-2">
              <input type="number" v-model="stage.price" placeholder="Precio" class="form-control" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" />
            </div>
            <div class="col-3 col-md-2">
              <input type="number" v-model="stage.quantity" placeholder="Cantidad" class="form-control" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" />
            </div>
            <div class="col-2 col-md-3">
              <select v-model="stage.status" @change="toggleModal(stage)" class="form-control" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif;">
                <option value="disponible">Disponible (Visible para todos)</option>
                <option value="privado">Privado: Solo con códigos o Links</option>
                <option value="noDisponible">No Disponible</option>
              </select>
            </div>
            <div class="col-12 d-flex align-items-center mt-2">
              <button type="button" @click="stage.showAdvanced = !stage.showAdvanced" class="btn btn-secondary btn-sm">Opciones Avanzadas</button>
              <button v-if="stage.isCreated==false" type="button" @click="removeStage(index)" class="btn btn-danger btn-sm ml-2"><i class="fas fa-trash-alt"></i></button>
            </div>

            <!-- Opciones Avanzadas -->
            <div class="col-12" v-if="stage.showAdvanced">
              <div class="advanced-options-container" style="background: #2A2A35; border: 1px solid rgba(204,204,204,0.36); border-radius: 15px; padding: 15px; margin-top: 15px;">
                <div class="form-group" style="margin-bottom: 15px;">
                  <label style="color: rgb(255,255,255); font-family: Quicksand, sans-serif;">Disponibilidad Desde</label>
                  <VueDatePicker v-model="stage.availableFrom" :style="{width: '100%'}" class="custom-datepicker" :enable-time="true" time-picker-inline />
                </div>
                <div class="form-group" style="margin-bottom: 15px;">
                  <label style="color: rgb(255,255,255); font-family: Quicksand, sans-serif;">Disponibilidad Hasta</label>
                  <VueDatePicker v-model="stage.availableUntil" :style="{width: '100%'}" class="custom-datepicker" :enable-time="true" time-picker-inline />
                </div>
              </div>
            </div>
          </div>

          <!-- Modal para ingresar el Código Principal -->
          <div v-if="showModal" class="modal-backdrop">
            <div class="modal-content">
              <h4 style="color: white; font-family: Quicksand, sans-serif; text-align: center;">Ingresa el Código Principal</h4>
              <input type="text" v-model="selectedStage.code" placeholder="Código Principal" class="form-control" style="color: rgb(255,255,255);background-color: #23232A; font-family: Quicksand, sans-serif; margin-top: 15px;" />
              <button @click="closeModal" class="btn btn-primary mt-3" style="width: 100%; font-family: Quicksand, sans-serif;">Guardar</button>
            </div>
          </div>
          <!-- Fin del Modal -->

          <div class="row mt-2">
            <div class="col-12">
              <button type="button" @click="addStage" class="btn btn-success">Añadir Etapa</button>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Guardar Cambios</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "EventCreationView",
  components: {
    VueDatePicker,
  },
  data() {
    return {
      stages: [{ name: "", price: "Precio", quantity: "Cantidad", showAdvanced: false, availableFrom: null, availableUntil: null, status: "disponible", isCreated: false, code: "" }],
      showModal: false,
      selectedStage: null,
    };
  },
  methods: {
    toggleModal(stage) {
      if (stage.status === "privado") {
        this.showModal = true;
        this.selectedStage = stage;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    addStage() {
      this.stages.push({ name: "", price: "Precio", quantity: "Cantidad", showAdvanced: false, availableFrom: null, availableUntil: null, status: "disponible", isCreated: false, code: "" });
    },
    removeStage(index) {
      this.stages.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: #2A2A35;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
}

.form-control {
  border-radius: 5px !important;
  padding: 10px;
  background-color: #23232A;
  color: rgb(255, 255, 255);
  font-family: Quicksand, sans-serif;
}

.btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.btn-primary:hover {
  background-color: #45a049;
  border-color: #45a049;
}

@media (max-width: 768px) {
  .col-4,
  .col-3,
  .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .modal-content {
    width: 90%;
  }
}

</style>
